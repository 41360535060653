/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import LoadingWaitSpace from "src/components/LoadingWaitSpace";
import NetServices from "src/utils/netservices";

import getLanguages from "src/utils/language";
import getFxsq from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import { IBlogRecord } from "src/models/smarttypes";
import Card from "src/components/Card";
import { ImList } from "react-icons/im";

const appFxs = getFxsq();
const languages = getLanguages();

const PreviewBlogPage = () => {
    const isOk = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [blogPost, setBlogPost] = useState<IBlogRecord | null>(null);
    const [featuredPost, setFeaturedPost] = useState<string>('');

    const [doctTitle, setDoctTitle] = useState<string>(languages.loading);

    document.title = `${doctTitle} | Alumni - ${languages.schoolNameOnly}`;
    

    const {postEltKey} = useParams();
    const navigate = useNavigate();

    const openInNewTab = (url: string) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    const clickcontentHandler = (e: any) => {
      const targetLink = e.target.closest("a");
      if (!targetLink) return;
      e.preventDefault();
      openInNewTab(targetLink.href);
    };

    const loadSelectedBlog = async () => {
        const oOper = await NetServices.requestGet(`alumni/getoneblogwithdetailbykey/${postEltKey}`, false);

        if(!oOper.bReturn){
            await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
            navigate('/app/main/blog');
            return;
        }

        const fRecord = oOper.oneBlogPostRecord!;

        setFeaturedPost(fRecord.post.featuredImageType === 'url' ? 
            `url(${fRecord.post.featuredImage})`: 
            `url(${appDecl.apiPath}/cloud_data/alumni_blogs_images/${fRecord!.post.featuredImage})`)
            
            setDoctTitle(fRecord.post.postTitle!);

        setBlogPost(fRecord);
        setIsLoading(false);
    
    }

    useEffect(() => {
      if(!isOk.current){
        isOk.current = true;
        loadSelectedBlog();
      }
    }, []);
    
    if (isLoading) return <LoadingWaitSpace />;
    return (
      <div className="flex flex-col gap-3">
        <Card>
          <div className="flex items-center">
            <h3 className="flex-1 text-lg font-bold text-gray-800">
              {blogPost!.post.postTitle}
            </h3>
            <div className="flex gap-1">
              <Link to={'/app/main/blog'} title={languages.listOfblogs}>
                <ImList />
              </Link>
            </div>
          </div>
          <div>{blogPost!.post.postSubject}</div>
        </Card>

        <Card>
            <div className="flex flex-col">
                <div className="aspect-video mb-4"
                    style={{
                        backgroundSize: 'cover',
                        backgroundImage: featuredPost,
                    }}></div>
                <div onClick={clickcontentHandler} 
                  className="blog-page" 
                  dangerouslySetInnerHTML={{__html: blogPost!.post.postContent}} />
            </div>
        </Card>
      </div>
    );
}

export default PreviewBlogPage