import { ReactNode } from 'react';
import ReactDom from 'react-dom';

import getLanguages from 'src/utils/language';

const languages = getLanguages();

interface IWiseModel {
    children: ReactNode,
    isOpen: boolean,
    onClose: (retData: any) => void,
    width?: number,
    titleBox?:  string,
    hasFooter?: boolean,
}

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '0px',
    zIndex: 1000,
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.7)',
    zIndex: 1000,
}

const WiseModal = ({
    children, 
    isOpen, 
    onClose,
    width,
    titleBox,
    hasFooter}: IWiseModel) => {
    if (!isOpen) return null;
    const nodeElement = document.getElementById('portals')!;
    const bFooter = hasFooter ?? true;

    
    return ReactDom.createPortal(
      <>
        <div style={{ ...OVERLAY_STYLES, position: "fixed" }}></div>
        <div style={{ 
            ...MODAL_STYLES, 
            borderRadius: '7px',
            overflow: 'hidden',
            position: "fixed", 
            width: !width ? '550px': `${width}px` }}>
            <div className='border-b border-slate-300 border-solid' style={{
                    padding: '10px 15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                }}>
                <span className='font-bold'>{titleBox ?? languages.dialogBoxTitle}</span>
                <button onClick={() => onClose(null)}><i className='fa fa-times'></i></button>
            </div>
            <div className='px-4 py-5'>
                {children}
            </div>
            {bFooter && <div className='dialog-footer flex gap-3 justify-end p-[15px] border-t border-slate-300 border-solid'>
                {/* <button className='w-btn-cancel rounded-lg border border-solid px-3 py-2 hover:border-slate-600 hover:bg-slate-400 hover:text-white'
                    onClick={() => onClose(null)}>
                    {languages.cancel}
                </button> */}
                <button className='w-btn-cancel rounded-lg border border-solid px-3 py-2 hover:border-slate-600 hover:bg-slate-400 hover:text-white'
                    onClick={() => onClose(null)}>
                    {languages.cancel}
                </button>
            </div>}
        </div>
      </>,
      nodeElement
    );
}

export default WiseModal