import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";

import getLang from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import SearchWebUmagesV2 from "../searchwebimagesv2/SearchWebUmagesV2";
import { IPexelsImage } from "src/models/smarttypes";
import MediaOrganizerFounder from "../mediaorganizer/MediaOrganizerFounder";

const languages = getLang();
const appFxs = generalFxs();

interface TinyMceEditorProps {
  getContent: (ev: string) => void;
  hasToolbar: boolean;
}

const TinyMceEditor = ({
  getContent,
  hasToolbar
}: TinyMceEditorProps) => {
  const onChange = (e: any) => {
    const htmlText: string = e.target.getContent();
    getContent(htmlText);
  };
  return (
    <div>
      <Editor
        apiKey="drbqpvxz302va0z0gkn51j8jy5e2ciajfg7ne1zx1n3ngdjd"
        initialValue=""        
        init={{
          width: '100%',
          branding: false,
          height:!hasToolbar ? 100: 400,
          plugins: !hasToolbar ? undefined: 
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar: !hasToolbar ? false: 
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          image_advtab: true,
          menubar: false,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default TinyMceEditor;






export const RealTinyMceEditor = ({
  getContent,
  hasToolbar
}: TinyMceEditorProps) => {
  const [urlsImagesVis, setUrlImagesVis] = useState(false);
  const [storedImagesVis, setStoredImagesVis] = useState(false); 

  const displayUrlImgs = () => {
    setUrlImagesVis(true);
  }
  const displayStoredImgs = () => {
    setStoredImagesVis(true);
  }

  const afterSearchLinks = (oImg: IPexelsImage | null) => {
    setUrlImagesVis(false);
    if(!oImg) return;
    navigator.clipboard.writeText(oImg!.src.tiny!);
    appFxs.showAlert(languages.photoImage, languages.photoImageLinkCp)
  }

  const afterLocalImages = (imagePath?: string) => {
    setStoredImagesVis(false);
    if(!imagePath) return;
    navigator.clipboard.writeText(imagePath!);
    appFxs.showAlert(languages.photoImage, languages.photoImageLinkCp)
  }

  return (
    <>
      <SearchWebUmagesV2
        isOpen={urlsImagesVis}
        titleBox={languages.copyOnlineImagesLink}
        onClose={afterSearchLinks}
        dialogWidth={650}
      />
      <MediaOrganizerFounder
        isOpen={storedImagesVis}
        onClose={afterLocalImages}
        dialogWidth={650}
      />
      <div className="flex flex-col gap-1">
        <div className="flex justify-end gap-2 mb-2">
          <span
            className="text-blue-400 cursor-pointer hover:text-blue-600"
            onClick={displayStoredImgs}
          >
            {languages.showMediaFiles}
          </span>
          <span className="mx-2">-</span>
          <span
            className="text-blue-400 cursor-pointer hover:text-blue-600"
            onClick={displayUrlImgs}
          >
            {languages.copyOnlineImagesLink}
          </span>
        </div>
        <TinyMceEditor getContent={getContent} hasToolbar={hasToolbar} />
      </div>
    </>
  );
}