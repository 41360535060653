/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
import { useState } from "react";
import { ISelectOptionType } from "src/models/smarttypes";
import generalFx from "src/utils/general_fx";
import getLanguage from "src/utils/language";

import './wisedropdown.css';

const language = getLanguage();
type WiseDropDownProps = {
    children?: React.ReactNode;
    options: ISelectOptionType[];
    label?: string;
    onSelected?: (e: ISelectOptionType) => void;
    hasDropdown?: boolean;
    position?: 'left' | 'right';
}
export default function WiseDropDown({children, options, label, onSelected, hasDropdown, position}: WiseDropDownProps) {
    const [isDropVis, setIsDropVis] = useState(false);
    const parentId = generalFx().generateUUID();
    const isDrop = hasDropdown ?? true;
    const positioning = position ?? 'left';

    window.addEventListener('click', (e: MouseEvent) => {
        try {
            if(!document.getElementById(parentId)!.contains(e.target as Node)){
                setIsDropVis(false);
            }
            
        } catch (error) {
            
        }
    })
    
    const onClick = () => {
        setIsDropVis(!isDropVis);
    }
    const onItemClick = (oVal: ISelectOptionType) => {
        if(!onSelected) {
            setIsDropVis(false);
            return;
        }
        onSelected(oVal);
        setIsDropVis(false);
    }
  return (
    <div className="wse-dropdown" id={parentId}>
        <span className={`wse-dropdown-toggle ${isDrop ? 'has-drop': ''}`} onClick={onClick}>
            {!children ? (label ?? language.clcickHereToSelect): children}
        </span>
        

        {options && options.length ? <ul className={`shadow-lg wse-dropdown-menu ${isDropVis ? 'show': ''} ${positioning==="right" ? 'w-right': ''}`}>
            {options.map(oElt => <li key={oElt.id} onClick={() => onItemClick(oElt)}>
                <a className="wse-dropdown-item">{oElt.label}</a>
            </li>)}
        </ul>: null}
    </div>
  )
}
