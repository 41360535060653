/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import appDecl from 'src/utils/declarations';

import Card from 'src/components/Card';
import { IAlumniUser, ISocialEvent, ISocialEventCplx, ISocialEventDetail } from 'src/models/smarttypes';

import NetServices from 'src/utils/netservices';
import { ImHistory } from 'react-icons/im';
import { useParams } from 'react-router-dom';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';
import { HTMLContentComponent } from 'src/components/htmlcontentcomp/HTMLContentComponent';


const languages = getLanguages();

const appFxs = generalFxs();



const SocialEventsPreviewPage = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [evtCplx, setEvtCplx] = useState<ISocialEventCplx | null>(null);
  const {eventKey} = useParams();



  const loadSpecificEvt = async (bAjax: boolean = false) => {
    const operLoad = await NetServices.requestGet(`alumni/getonsesocialeventforweb/${eventKey}`, bAjax)
    if(!operLoad.bReturn){
      appFxs.showAlert(operLoad.msgTitle, operLoad.msgBody);
      return;
    }

    setEvtCplx(operLoad.socialEventCplx!);
    setLoading(false);
  }
  useEffect(() => {
    loadSpecificEvt();
  }, []);

  if(isLoading) return <LoadingWaitSpace />

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Header */}
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {evtCplx ? evtCplx.socialEvent.eventTitle : languages.loading}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                    onClick={() => loadSpecificEvt(true)}
                  >
                    <ImHistory />
                  </button>
                </div>
              </div>
              {evtCplx && (
                <div className="flex gap-3">
                  {evtCplx.socialEvent.eventTags.map((oTag, idx) => (
                    <SocialEvtTag tagName={oTag} key={idx} />
                  ))}
                </div>
              )}
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        <div className="bg-[#f0f2f5]">
          <div className="flex flex-col justify-center max-w-[500px] mx-auto py-6">
            <ListSocialEventDetail socialDetail={evtCplx!} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialEventsPreviewPage;

const SocialEvtTag = ({tagName}: {tagName: string}) => {
  return(
    <div className='flex flex-wrap items-center font-semibold text-sm border border-solid border-slate-300 px-4 py-1 rounded-lg hover:bg-slate-300 hover:text-black'>
      #{tagName}
    </div>
  );
}



const ListSocialEventDetail = ({socialDetail}: {socialDetail: ISocialEventCplx}) => {
  const {lstDetails, publisher, socialEvent} = socialDetail;

  return (
    <div className="flex flex-col">
      {lstDetails.map((oDetail, idx) => 
        <SocialEventDetElt 
          key={idx} 
          detail={oDetail} 
          publisher={publisher}
          mainElt={socialEvent} 
        />)}
    </div>
  );
}

const SocialEventDetElt = ({detail, publisher, mainElt}: 
  {
    detail: ISocialEventDetail, 
    publisher: IAlumniUser,
    mainElt: ISocialEvent,
  }) => {

    const dayDate = appFxs.formatDateWithHrsSpec(mainElt.eventDate)


    return (
      <div className="bg-white shadow-sm rounded-md mb-5">
        <div className="flex flex-col">
          <div className="event-header m-[12px] flex items-center">
            <div>
                <div className='w-[40px] h-[40px] rounded-full border border-solid border-slate-400' 
                    style={{
                        backgroundImage: `url(${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${publisher.profilePicturePath ?? 'e0f1031e98db40d6b9da0c309f993ab4.png'})`,
                        backgroundSize: 'cover'}}></div>
            </div>
            <div className="flex-1 ml-3">
                <div className='flex flex-col'>
                    <div className=''>
                        <span className='text-sm font-semibold text-black'>{`${publisher.firstName} ${publisher.lastName}`}</span>
                    </div>
                    <div className='text-sm text-black'>
                      <span>{dayDate}</span>
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-1">
              
            </div>
          </div>
          <div className="event-body">
            <HTMLContentComponent 
              postContent={detail.description!} 
              isBlog={false} 
              cssStyle="data-content px-4 pb-4 text-sm leading-[22px]" />
            <div className='data-picture bg-[#A19690]'>
              <div className="inside">
                <div className='pt-[100%] relative overflow-hidden'>
                  <div className='final-inside'>
                    <div className="w-full h-full absolute flex items-center justify-center">
                    <img alt='' 
                      src={`${appDecl.apiUrlTest}/cloud_data/alumni_social_events/${detail.eventKey}/${detail.eventPhotoName}`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="event-footer"> 
            <div className="ftitle text-sm font-bold p-2 text-black">{detail.photoTitle}</div>
            <div className="fetitle text-sm font-mono pb-2 px-2">
              <strong className='text-black'>{languages.in.toLowerCase()}</strong>&nbsp;
              <span className="text-black">{mainElt.eventTitle}</span>
            </div>
          </div>
        </div>
      </div>
    );
}













