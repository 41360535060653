/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import Card from 'src/components/Card';
import { ImHistory, ImPlus } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';
import { ISocialEventCplx } from 'src/models/smarttypes';
import moment from 'moment';
import NetServices from 'src/utils/netservices';


const languages = getLanguages();
const appFxs = generalFxs();




export const SocialEventsPage = () => {
  document.title = `${languages.socialevents} | Alumni - ${languages.schoolNameOnly}`;
  const [isLoading, setIsLoading] = useState(true);
  const [lstEvents, setLstEvents] = useState<ISocialEventCplx[]>([]);
  const bLoaded = useRef(false);

  const navigate = useNavigate();

  const addNewSocialEvt = () => {
    navigate('/app/main/socialevents/new');
  }
  const refreshList = async (bAjax: boolean = false) => {
    const operation = await NetServices.requestGet('alumni/getlistallsocialeventsbyuser', bAjax);

    if(!operation.bReturn){
      await appFxs.showAlert(operation.msgTitle, operation.msgBody);
      navigate('/app/main/dashboard')
      return;
    }

    setLstEvents(operation.lstAllSocialEvents!);
    setIsLoading(false);
  }

  const afterPublish = (lst: ISocialEventCplx[]) => {
    setLstEvents(lst);
  }

  useEffect(() => {
    if(bLoaded.current)return;
    refreshList(false);
    bLoaded.current = true;
  }, [])

  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.socialevents}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={addNewSocialEvt}
                >
                  <ImPlus />
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                  onClick={() => refreshList(true)}
                >
                  <ImHistory />
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.socialEventsMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* Body */}
      <Card>
        <p className="text-2xl font-bold mb-2 text-black">{languages.events}</p>
        <div className="flex flex-wrap gap-3">
          <div className="overflow-x-auto w-full">
            <table className="table table-zebra">
              <thead>
                <tr>
                  <th>{languages.event}</th>
                  <th>{languages.date}</th>
                  <th>{languages.tags}</th>
                  <th>{languages.details}</th>
                  <th>{languages.comments}</th>
                  <th>{languages.status}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <div className="py-4 text-center bg-gray-300">
                        <span className="text-black block">
                          <i className="fa fa-spin fa-spinner fa-3x"></i>
                        </span>
                        <h4 className="text-black my-4 font-bold text-2xl">
                          {languages.loadSocEvts}
                        </h4>
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && lstEvents.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div className="py-4 text-center bg-gray-300">
                        <span className="block text-black font-semibold text-lg">
                          {languages.noblogFnd}
                        </span>
                        <div className="flex items-center justify-center py-5">
                          <button
                            type="button"
                            className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                            onClick={addNewSocialEvt}
                          >
                            <ImPlus />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {lstEvents.map((oEvent, idx) => (
                  <TableRowBlog
                    afterPublish={afterPublish}
                    evtData={oEvent}
                    key={idx}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default SocialEventsPage;

interface ITableRowSocEvent {
  evtData: ISocialEventCplx,
  afterPublish: (lst: ISocialEventCplx[]) => void
}

const TableRowBlog = ({evtData, afterPublish}: ITableRowSocEvent) => {
  const {socialEvent, lstDetails, lstComments} = evtData;

  return (
    <tr>
      <td className="text-black">
        <Link className="text-blue-500 hover:text-blue-700" to={`/app/main/socialevents/preview-event/${socialEvent.eventKey}`}>{socialEvent.eventTitle}</Link>
      </td>
      <td className="text-black">{moment(socialEvent.eventDate).format('Do MMMM gggg')}</td>
      <td className="text-black">{socialEvent.eventTags.length}</td>
      <td className="text-black">{lstDetails.length}</td>
      <td className="text-black">
        <SocialEventCommentsPost 
          evtKey={socialEvent.eventKey}
          nbrCmnts={lstComments.length}  />
      </td>
      <td className="text-black">
        <PublishStatusComp 
          eventKey={socialEvent.eventKey}
          published={socialEvent.published}
          afterPublish={afterPublish} /> 
      </td>
    </tr>
  )
}

interface IPublishEventStatus {
  eventKey: string, 
  published: boolean,
  afterPublish: (lst: ISocialEventCplx[]) => void
}
const PublishStatusComp = ({published, eventKey, afterPublish}: IPublishEventStatus) => {
  const onPublishPost = async () => {
    const bAsk = await appFxs.showConfirm(languages.publish, languages.publishPostMsg);
    if(!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost('alumni/publishsinglesocialevent', {
      eventKey
    },true);

    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }   
    const lst =  oOper.lstAllSocialEvents!;
    afterPublish(lst);
  }

  return (
    <div>
      {published && <span>{languages.published}</span>}
      {!published && 
        <button className="btn btn-sm bg-slate-400" onClick={onPublishPost}>
          {languages.publish}
        </button>
      }
    </div>
  );
}
const SocialEventCommentsPost = ({evtKey, nbrCmnts}:{evtKey: string, nbrCmnts: number}) => {
  return (
    <>
    {nbrCmnts === 0 && <span>{nbrCmnts}</span>}
    {nbrCmnts > 0 && 
      <Link className="text-blue-400 hover:text-blue-500"  
        to={`/app/main/socialevents/preview-comments/${evtKey}`}>
        {nbrCmnts} {languages.comments.toLowerCase()}
      </Link>
    }
    </>    
  );
}
