import { Outlet } from "react-router-dom";


import appLanguages from 'src/utils/language';
//import generalFxs from 'src/utils/general_fx';

const languages = appLanguages();
//const appFxs = generalFxs();

export const AppLayout = () => {
  const year = (new Date()).getFullYear();
  return (
    <div className="h-screen flex flex-col">
      <div className="flex-1">
        <Outlet/>
      </div>
      <footer className="bg-[#0b5b13]">
        <div className="p-5 flex flex-col items-center md:flex-row md:justify-between">
          <p className="text-white font-semibold">
            &copy;&nbsp;{year}&nbsp;<a target="_blank" href="https://wiseobjects.com" rel="noreferrer">WiseObjects Ltd</a>
          </p>
          <div className="flex items-center gap-4 py-2 flex-wrap">
            <a className="text-white hover:text-gray-300" rel="noreferrer" target="_blank" href="https://lacolombiere.ac.rw/legal/terms">{languages.terms_serv}</a>
            <a className="text-white hover:text-gray-300" rel="noreferrer" target="_blank" href="https://lacolombiere.ac.rw/legal/cookies">{languages.cookie_policy}</a>
            <a className="text-white hover:text-gray-300" rel="noreferrer" target="_blank" href="https://lacolombiere.ac.rw/legal/privacy">{languages.priv_policy}</a>
          </div>
        </div>
      </footer>
    </div>
  )
}
