/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { ISchoolQuote } from "src/models/smarttypes";

import quoteImg1 from 'src/assets/images/quotes/qte-img-1.jpg';
import quoteImg2 from 'src/assets/images/quotes/qte-img-2.jpg';
import quoteImg3 from 'src/assets/images/quotes/qte-img-3.jpg';
import quoteImg4 from 'src/assets/images/quotes/qte-img-4.jpg';
import quoteImg5 from 'src/assets/images/quotes/qte-img-5.jpg';
import quoteImg6 from 'src/assets/images/quotes/qte-img-6.jpg';
import quoteImg7 from 'src/assets/images/quotes/qte-img-7.jpg';
import quoteImg8 from 'src/assets/images/quotes/qte-img-8.jpg';
import quoteImg9 from 'src/assets/images/quotes/qte-img-9.jpg';
import quoteImg10 from 'src/assets/images/quotes/qte-img-10.jpg';
import quoteImg11 from 'src/assets/images/quotes/qte-img-11.jpg';
import quoteImg12 from 'src/assets/images/quotes/qte-img-12.jpg';
import quoteImg13 from 'src/assets/images/quotes/qte-img-13.jpg';
import quoteImg14 from 'src/assets/images/quotes/qte-img-14.jpg';
import quoteImg15 from 'src/assets/images/quotes/qte-img-15.jpg';
import quoteImg16 from 'src/assets/images/quotes/qte-img-16.jpg';
import quoteImg17 from 'src/assets/images/quotes/qte-img-17.jpg';
import quoteImg18 from 'src/assets/images/quotes/qte-img-18.jpg';

const qteImages = [
  quoteImg1, quoteImg2,quoteImg3,quoteImg4,quoteImg5,quoteImg6,
  quoteImg7,quoteImg8,quoteImg9,quoteImg10,quoteImg11,quoteImg12,
  quoteImg13,quoteImg14,quoteImg15,quoteImg16,quoteImg17,quoteImg18
]
const schoolQuotes:ISchoolQuote[] = [
  {
    author: 'Mark Twain',
    quote: 'The man who does not read books has no advantage over the one who cannot read them.'
  },
  {
    author: 'Malcolm X',
    quote: 'Education is the passport to the future, for tomorrow belongs to those who prepare for it today.'
  },
  {
    author: 'Chinese proverb',
    quote: 'Teachers can open the door, but you must enter it yourself.'
  },
  {
    author: 'B.B. King',
    quote: 'The beautiful thing about learning is that no one can take it away from you.'
  },
  {
    author: 'B.B. King',
    quote: 'Education is the most powerful weapon you can use to change the world.'
  },
  {
    author: 'Plutarch',
    quote: 'The mind is not a vessel to be filled but a fire to be ignited.'
  },
  {
    author: 'John Wooden',
    quote: "Don't let what you cannot do interfere with what you can do."
  },
  {
    author: 'Albert Einstein',
    quote: "A person who never made a mistake never tried anything new."
  },
  {
    author: 'Babe Ruth',
    quote: "Never let the fear of striking out stop you from playing the game."
  },
  {
    author: 'Mason Cooley',
    quote: "Procrastination makes easy things hard and hard things harder."
  },
  {
    author: 'Zig Ziglar',
    quote: "You don't have to be great to start, but you have to start to be great."
  },
  {
    author: 'Helen Hayes',
    quote: "The expert in anything was once a beginner."
  },
  {
    author: 'Walt Disney',
    quote: "The way to get started is to quit talking and begin doing."
  },
  {
    author: 'Beverly Stills',
    quote: "There are no shortcuts to any place worth going."
  },
]




export const AccountLayout = () => {
  const startPosImg = Math.floor(Math.random() * qteImages.length);
  const startPosQte = Math.floor(Math.random() * schoolQuotes.length);




  const [bgImage, setBgImage] = useState(qteImages[startPosImg]);
  const [qteRecord, setQteRecord] = useState(schoolQuotes[startPosQte]);
  const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
  setBgImage(qteImages[startPosImg]);
  setQteRecord(schoolQuotes[startPosQte]);
  setIsLoading(false);
}, [])

  return (
    <div className="h-screen flex items-stretch">
      <div className="relative basis-full md:basis-[41%] lg:basis-1/3"> 
        <div className="data-series-history">
          <Outlet />
        </div>        
      </div>
      {!isLoading &&
        <div className="bgqteimg relative hidden md:block md:basis-[59%] lg:basis-2/3"
          style={{backgroundImage: `url(${bgImage})`, backgroundColor: '#fefefe'}}>
            <div className="h-screen flex items-end">
              <div className="quote-cover text-center">
                <span className="quote-elt mb-1">
                  "{qteRecord.quote}"
                </span>
                <strong className="quote-owner">{qteRecord.author}</strong>
              </div>
            </div>
        </div>
      }
    </div>
  )
}
