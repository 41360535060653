/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";

import getLanguage from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { IAlumniBlogPostComment, IBlogRecord } from "src/models/smarttypes";
import { useNavigate, useParams } from "react-router-dom";
import LoadingWaitSpace from "src/components/LoadingWaitSpace";
import NetServices from "src/utils/netservices";
import Card from "src/components/Card";
import { ImHistory } from "react-icons/im";
import { BiChat, BiTrash } from "react-icons/bi";

const languages = getLanguage();
const appFxs = generalFxs();
const isEng = appFxs.getLocalLanguage() === 'en';



const PreviewBlogComments = () => {
  document.title = `${languages.previewComments} | ${languages.schoolName}`;
  const [loading, setLoading] = useState(true);
  const [blogRecord, setBlogRecord] = useState<IBlogRecord | null>(null);
  const [lstComments, setLstComments] = useState<IAlumniBlogPostComment[]>([]);

  const { postEltKey } = useParams();
  const bLoaded = useRef(false);
  const navigate = useNavigate();

  const populateData = async (bAjax: boolean = false) => {
    const loadDataOper = await NetServices.requestGet(
      "alumni/getoneblogwithdetailbykey/" + postEltKey,
      bAjax
    );

    if (!loadDataOper.bReturn) {
      navigate("/app/main/blog");
    } else {
      setBlogRecord(loadDataOper.oneBlogPostRecord!);
      setLstComments(loadDataOper.oneBlogPostRecord!.comments);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bLoaded.current) return;
    populateData();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Header */}
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {loading && <>{languages.loading}...</>}
                  {!loading && <span>{languages.comments}</span>}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                    onClick={() => populateData(true)}
                  >
                    <ImHistory />
                  </button>
                </div>
              </div>
              <div className="mt-1 text-gray-800 dark:text-gray-400">
                {loading && <span>{languages.loading}...</span>}
                {!loading && (
                  <div className="flex items-stretch justify-between mt-4">
                    <span>{blogRecord!.post.postTitle}</span>
                    <span className="flex items-center">
                      <BiChat />
                      &nbsp;<span>{lstComments.length}</span>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        {loading && <LoadingWaitSpace />}
        {!loading && (
          <ListPostCommentsComp
            lstComments={lstComments}
            afterDelete={(lst) => setLstComments(lst)}
          />
        )}
      </div>
    </>
  );
};

export default PreviewBlogComments;

const ListPostCommentsComp = ({
  lstComments,
  afterDelete,
}: {
  lstComments: IAlumniBlogPostComment[];
  afterDelete: (lst: IAlumniBlogPostComment[]) => void;
}) => {
  const itemsPerPage = 5;
  const [pageStart, setPageStart] = useState<number>(0);
  const [pageEnd, setPageEnd] = useState<number>(0);

  const onPageChanged = (iPage: number) => {
    const fStart = itemsPerPage * iPage + 1;
    const fEnd = fStart + itemsPerPage - 1;
    const realEnd = fEnd > lstComments.length ? lstComments.length : fEnd;

    setPageStart(fStart);
    setPageEnd(realEnd);
  };

  return (
    <>
      <PaginatedCommentsComp
        items={lstComments}
        itemsPerPage={itemsPerPage}
        onChange={onPageChanged}
        pageStart={pageStart}
        pageEnd={pageEnd}
        afterDelete={afterDelete}
      />
    </>
  );
};

const PostCommentComp = ({
  comment,
  afterDelete,
}: {
  comment: IAlumniBlogPostComment;
  afterDelete: (lst: IAlumniBlogPostComment[]) => void;
}) => {
  const onDeleteComment = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteCommentMsg
    );
    if (!bAsk.isConfirmed) return;

    const data = {
      blogPostKey: comment.postKey,
      blogCommentKey: comment.commentKey,
    };
    const deleteOper = await NetServices.requestPost(
      "alumni/deleteoneblogcomment",
      data,
      true
    );
    if (!deleteOper.bReturn) {
      appFxs.showAlert(deleteOper.msgTitle, deleteOper.msgBody);
      return;
    }

    afterDelete(deleteOper.lstOfBlogComments!);
  };
  return (
    <div className="blog-comments-list mt-[40px] bg-white py-0  md:py-[3em] md:px-[4em] lg:py-[5.34em] lg:px-[6.67em]">
      <div className="flex flex-col">
        <div className="flex items-center gap-3 mb-3">
          <img
            src="https://secure.gravatar.com/avatar/b6abdb61046e5bf43bbced0a8a85f5c4?s=50&d=mm&r=g"
            alt=""
            className="rounded-full w-[50px] h-[50px]"
          />
          <div className="text-red-500">
            <div className="leading-[1] mb-1">{comment.commenterName}</div>
            <div className="leading-[1]">
              {appFxs.formatDateWithHrs(comment.commentDate)}
            </div>
          </div>
        </div>
        <section>
          <p className="leading-[1.5] text-stone-700">{comment.comment}</p>
        </section>
        <div className="flex justify-between mt-4">
          <div className="flex items-center"></div>
          <div className="flex items-center">
            <button
              title={languages.deleteComment}
              className="btn text-white bg-red-400 btn-outline"
              onClick={onDeleteComment}
            >
              <BiTrash />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ICommentPage {
  itemsPerPage: number;
  items: IAlumniBlogPostComment[];
  pageStart: number;
  pageEnd: number;
  onChange: (pagePos: number) => void;
  afterDelete: (lst: IAlumniBlogPostComment[]) => void;
}
const PaginatedCommentsComp = ({
  itemsPerPage,
  items,
  onChange,
  pageEnd,
  pageStart,
  afterDelete,
}: ICommentPage) => {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    if (onChange === null) return;
    onChange!(Number(event.selected));
  };

  return (
    <div className="flex flex-col gap-2">
      <ActualCommentsDisplayer
        allItems={items}
        items={currentItems}
        pageEnd={pageEnd}
        pageStart={pageStart}
        afterDelete={afterDelete}
      />
      <div className="flex justify-end py-4 mt-2">
        <ReactPaginate
          breakLabel="..."
          nextLabel={`${languages.next.toLowerCase()} >`}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={`< ${languages.previous.toLowerCase()}`}
          renderOnZeroPageCount={null}
          containerClassName="wise-pagination-c"
          activeClassName="wise-p-active"
        />
      </div>
    </div>
  );
};

const ActualCommentsDisplayer = ({
  allItems,
  items,
  pageEnd,
  pageStart,
  afterDelete,
}: {
  allItems: IAlumniBlogPostComment[];
  items: IAlumniBlogPostComment[];
  pageStart: number;
  pageEnd: number;
  afterDelete: (lst: IAlumniBlogPostComment[]) => void;
}) => {
  const MessageDisplayer = () => {
    return (
      <div className="filter-result my-[10px] flex justify-end">
        {isEng && (
          <p>
            Showing {pageStart}-{pageEnd} of {allItems.length} results
          </p>
        )}
        {!isEng && (
          <p>
            Affichage de {pageStart} à {pageEnd} sur {allItems.length} résultats
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      {items.map((o, idx) => (
        <PostCommentComp comment={o} key={idx} afterDelete={afterDelete} />
      ))}
      {items.length > 0 && <MessageDisplayer />}
    </>
  );
};