/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { ImHistory, ImPlus } from 'react-icons/im';
import moment from 'moment';


import { EventAndStatus, ICalendarEvent, ICalendarEventSend } from 'src/models/smarttypes';
import { DatesSetArg, EventClickArg } from '@fullcalendar/core/index.js';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import Card from 'src/components/Card';
import NetServices from 'src/utils/netservices';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';
import CalendarEventForm from 'src/components/eventformwrapper/CalendarEventForm';

const languages = getLanguages();
const appFxs = generalFxs();
const calendarEventEmpty:ICalendarEvent = {
  eventKey: '',
  userEmail: '',
  allDay: false,
  backgroundColor: 'blue',
  start: new Date(),
  end: new Date(),
  id: '-',
  groupId: '-',
  title: '',
  textColor: '#fff',
  hasEnd: false,
}


const EventsPage = () => {
  const [calendarEvents, setCalendarEvents] = useState<EventAndStatus[]>([]);
  const [selectedMonthDate, setMonthDate] = useState<Date>(new Date());
  const [isLoading, setLoading] = useState<boolean>(true);

  const [formData, setFormData] = useState<ICalendarEvent>({...calendarEventEmpty});
  const [eventRecordStatus, setEventRecordStatus] = useState<number>(0);
  const [isUserOwner, setUserOwner] = useState<boolean>(false);
  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  

  const isLoaded = useRef(false);


  const saveRecordToDB = async (fData: ICalendarEvent) => {
    const startMonth = selectedMonthDate.getMonth() + 1;
    const startYear = selectedMonthDate.getFullYear();


    const finalSend: ICalendarEventSend = {
      eventKey: appFxs.generateUUID(),
      userEmail: '',
      allDay: fData.allDay,
      backgroundColor: fData.backgroundColor!,
      textColor: '#fff',
      id: appFxs.generateUUID(),
      groupId: appFxs.generateUUID(),
      start: appFxs.dateToUtcStr(fData.start),
      end: appFxs.dateToUtcStr(fData.start),
      hasEnd: fData.hasEnd,
      title: fData.title,
    }
    const data = {
      calendarEventOper: finalSend,
      periodMonth: startMonth,
      periodYear: startYear,
    }

    setFormOpen(false);    
    setEventRecordStatus(0);
    
    const saveOper = await NetServices.requestPost('alumni/savecalendereventoper', data, true);
    if(!saveOper.bReturn){
      appFxs.showAlert(saveOper.msgTitle, saveOper.msgBody);
      return;
    }

    setCalendarEvents(saveOper.listAllCalendarEvents!); 
  }
  const addNewEvent = () => {    
    const todayDate = new Date();    
    const periodMonth = selectedMonthDate.getMonth() + 1;
    const periodYear = selectedMonthDate.getFullYear();

    const selDate = new Date(
      periodYear, 
      periodMonth, 
      todayDate.getDate(), 

      todayDate.getHours(), 
      todayDate.getMinutes(), 
      todayDate.getSeconds()
    );
    
    const newOper = {...calendarEventEmpty, start: selDate, end: selDate}
    setFormData(newOper);
    setFormOpen(true);

  }
  const handleDateClick = (arg: DateClickArg) => { 
    const todayDate = new Date();    
    
    const selDate = new Date(
      arg.date.getFullYear(), 
      arg.date.getMonth(), 
      arg.date.getDate(), 

      todayDate.getHours(), 
      todayDate.getMinutes(), 
      todayDate.getSeconds()
    );
    
    const newOper = {...calendarEventEmpty, start: selDate, end: selDate}
    setFormData(newOper);
    setFormOpen(true);
  }
  const editEventRecord = async (e: EventClickArg) => {
    const {id} = e.event;
    const operGet = await NetServices.requestGet(`alumni/geteventbyitsid/${id}`, true);
    if(!operGet.bReturn){
      appFxs.showAlert(operGet.msgTitle, operGet.msgBody);
      return;
    }

    const f = operGet.calendarEventRec!;
    const isOwner = operGet.isOwner!;

    setFormData(f);
    setEventRecordStatus(1);
    setUserOwner(isOwner)
    setFormOpen(true);
  }

  const loadCalendarEvents = async (bAjax: boolean = false) => {
    const startMonth = selectedMonthDate.getMonth() + 1;
    const startYear = selectedMonthDate.getFullYear();

    const loadOper = await NetServices.requestGet(`alumni/getlistofcalendareventspermonth/${JSON.stringify(startMonth)}/${JSON.stringify(startYear)}`, bAjax);
    if(!loadOper.bReturn){
      await appFxs.showAlert(loadOper.msgTitle, loadOper.msgBody);
      return;
    }

    setCalendarEvents(loadOper.listAllCalendarEvents!);    
    setLoading(false);
  }
  const setMonthToApply = (e: DatesSetArg) => {
    console.log('e', e);
    
    const start = e.start;

    setMonthDate(start);
  }
  const deleteEventRecord = async (fData: ICalendarEvent) => {
    const periodMonth = selectedMonthDate.getMonth() + 1;
    const periodYear = selectedMonthDate.getFullYear();

    setFormOpen(false);
    setEventRecordStatus(0);

    const oData = {
      eventId: fData.id,
      periodYear,
      periodMonth,
    };
    

    const delOper = await NetServices.requestPost('alumni/deleteeventbyitsid', oData, true);
    if(!delOper.bReturn){
      appFxs.showAlert(delOper.msgTitle, delOper.msgBody);
      return;
    }

    setCalendarEvents(delOper.listAllCalendarEvents!);  
  }


  useEffect(() => {
    if(isLoaded.current)return;
    loadCalendarEvents(false)
    isLoaded.current = true;
  }, [selectedMonthDate]);


  if(isLoading) return <LoadingWaitSpace />

  return (
    <>
      <CalendarEventForm
        dialogWidth={550}
        isOpen={isFormOpen}
        onClose={() => {
          setFormOpen(false);
          setEventRecordStatus(0);
        }}
        defaultValue={formData}
        isUserOwner={isUserOwner}
        eventRecordStatus={eventRecordStatus}
        onDelete={(d) => deleteEventRecord(d)}
        onSaveRecord={d => saveRecordToDB(d)}
      />
      <div className="flex flex-col gap-3">
        {/* Header */}
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.calendarEvents}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={addNewEvent}
                  >
                    <ImPlus />
                  </button>
                  <button
                    type="button"
                    className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                    onClick={() => loadCalendarEvents(true)}
                  >
                    <ImHistory />
                  </button>
                </div>
              </div>
              <p className="mt-1 text-gray-800 dark:text-gray-400">
                {languages.calendarEventsMsg}
              </p>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        {/* Body */}
        <Card>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            weekends={false}
            events={calendarEvents.map(o => o.event)}
            eventContent={CalendarEventContent}
            dateClick={handleDateClick}
            eventClick={editEventRecord}
            datesSet={setMonthToApply}
          />
        </Card>
      </div>
    </>
  );
}

export default EventsPage;

const CalendarEventContent = (eventInfo:any) => {
  const event = eventInfo.event as ICalendarEvent;
  const {start, backgroundColor, textColor, title} = event;
  const startDate = moment(start).format('HH:mm');
  const tColor = textColor ?? '#fff';

 return (
  <div title={title} className='flex gap-1 w-full'
  style={{
    backgroundColor: backgroundColor,
    color: tColor,
    overflow: 'hidden'
  }}>
      <b>{startDate}</b>
      <i className='line-clamp-1 overflow-hidden'>{title}</i>
  </div>
  )
}
