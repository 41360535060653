/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";


import alumniImg from 'src/assets/images/alumniimg.png';

import {ReactComponent as CalendarIconSvg} from 'src/assets/images/svg/calendar-icon.svg'
import {ReactComponent as MessageIconSvg} from 'src/assets/images/svg/message-icon.svg'
import {ReactComponent as SocialIconSvg} from 'src/assets/images/svg/social-icon.svg'
import {ReactComponent as BlogIconSvg} from 'src/assets/images/svg/blog-icon.svg'
import {ReactComponent as ServerErrorIconSvg} from 'src/assets/images/svg/server-error.svg'


import getLanguages from 'src/utils/language';
import generalFx from 'src/utils/general_fx';
import appDecl from 'src/utils/declarations';

import Card from "src/components/Card";
import WiseDropDown from "src/components/wsedropdown/WiseDropDown";
import NetServices from "src/utils/netservices";
import { IAlumniMember, IStatResult } from "src/models/smarttypes";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";
import { Link } from "react-router-dom";

const languages = getLanguages();
const appFxs = generalFx();
const startMonth = (new Date()).getMonth() + 1;


const months: { id: string; label: string }[] = appFxs.wdMonthsShrt.map(
  (o, i) => {
    return { id: (i + 1).toString(), label: o };
  }
);

export default function DashboardPage() {
  document.title = `${languages.dashboard} | Alumni - ${languages.schoolNameOnly}`;
  const [month, setMonth] = useState<number>(startMonth)
  const [monthName, setMonthName] = useState<string>(languages.loading);

  useEffect(() => {
    setMonthName(appFxs.wdMonthsShrt[month-1])
  }, [month]);
  
  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <h3 className="text-lg font-bold text-gray-800">
              {languages.dashboard}
            </h3>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.dashboardUserMsg}
            </p>
          </div>
          
        </div>
      </Card>
      {/* Body */}
      <Card>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center  mb-2">
            <p className="text-xl font-bold">{languages.details}</p>
            <WiseDropDown
              label={monthName}
              hasDropdown={false}
              options={months}
              position="right"
              onSelected={(e) => {
                setMonth(Number(e.id));
              }}
            >
              <span className="flex items-center justify-center px-3 py-0 border border-solid rounded-2xl min-w-[120px]">
                <div className="flex items-center w-full gap-1">
                  <i className="fa fa-calendar"></i>
                  <span className="flex-1">{monthName}</span>
                  <i className="fa fa-caret-down"></i>
                </div>
              </span>
            </WiseDropDown>
          </div>
          <div className="flex flex-wrap border border-solid rounded-[1rem] border-slate-200 shadow">
            <DashStatCom statType="blogs" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="social" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="messages" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="events" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
          </div>
          <AlumniMembersComp />
        </div>

      </Card>
    </div>
  );
}


type DashStatComProps = {
  statType: 'blogs' | 'social' | 'messages' | 'events';
  month: number;
  classes?: string
}

const DashStatCom = ({statType, month, classes}: DashStatComProps) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isServerError, setServerError] = useState<boolean>(false);

  const [statRec, setStatRec] = useState<IStatResult | null>(null);

  const textType = 
    statType === 'blogs' ? languages.blogs:
    statType === 'social' ? languages.social:
    statType === 'messages' ? languages.messages:
    statType === 'events' ? languages.events:
    languages.blogs;
  const SVGIcon = 
    statType === 'blogs' ? BlogIconSvg:
    statType === 'social' ? SocialIconSvg:
    statType === 'messages' ? MessageIconSvg:
    statType === 'events' ? CalendarIconSvg:
    BlogIconSvg;

  const getDataFromNetwork = async () => {
    setServerError(false);
    setLoading(true);
    const oPerData = await NetServices.requestGet(`alumni/getdashboardstats/${statType}/${month}`)
    if(!oPerData.bReturn){
      setServerError(true);
      setLoading(false);
      return;
    }

    setStatRec(oPerData.statisticsRecord!);

    setServerError(false);
    setLoading(false);
  }


  useEffect(() => {
    //if(bLoaded.current) return;
    getDataFromNetwork();
    //bLoaded.current = true;
  }, [month]);

  if(isLoading) {
    return (
      <div className={`w-[192px] h-[104px] flex items-center justify-center ${classes && classes}`}>
        <div className="flex flex-col items-center justify-center">
          <i className="fa fa-spinner fa-spin fa-3x"></i>
          <span className="text-gray-600 text-sm">{textType}</span>
        </div>
      </div>
    );
  }

  if(!isLoading && isServerError){
    return (
      <div className="w-[192px] h-[104px] flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <ServerErrorIconSvg width={32} height={32} />
          <span className="text-gray-600 text-sm">{languages.serverError}</span>
        </div>
      </div>
    );
  }
 
  return (
    <div className={`w-fit flex gap-3 py-[1rem] px-[1.5rem] items-center ${classes && classes}`}>
      <div className="flex-1 w-[100px] flex flex-col justify-center">
        <div className="w-stat-desc whitespace-nowrap leading-[1rem] text-[1rem] text-gray-400">
          {textType}
        </div>
        <div className="w-stat-value whitespace-nowrap leading-[2.5rem] text-[2.25rem] font-extrabold">
          {statRec?.value}
        </div>
        <div className="w-stat-desc whitespace-nowrap leading-[1rem] text-[0.75rem] text-gray-400 flex items-center gap-1">
          <StatVarComp varType={statRec?.arrowStyle!}  />
          <span>{statRec?.prevValue}</span>
          <span>({statRec?.percentValue!})</span>
        </div>
      </div>
      <div className="text-secondary">
        <SVGIcon width={32} height={32} stroke="green" />
      </div>
    </div>
  );
}
type StatVarCompProps = 'up' | 'down' | 'stable';
const StatVarComp = ({varType}: {varType: StatVarCompProps}) => {
  return(
    <>
      {varType === "up" && <span>&#8599;</span>}
      {varType === "down" && <span>&#8600;</span>}
      {varType === "stable" && <span>&#8605;</span>}
    </>
  );
}




export const AlumniMembersComp = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setserverError] = useState<boolean>(false);
  const [alumniMembers, setAlumniMembers] = useState<IAlumniMember[]>([]);

  const populateListMembers = async () => {
    setLoading(true);
    setserverError(false);
    const oper = await NetServices.requestGet('alumni/getallmembers', false);

    if(!oper.bReturn){
      setserverError(true);
      setLoading(false);
      return;
    }

    setAlumniMembers(oper.allAlumniMembers!);
    setLoading(false);
  }

  const requestFollowMelber = async (member: IAlumniMember, status: number) => {
    const oData = {
      encrReqFollowEmail: window.btoa(member.member.userEmail),
      statusRequest: status,
    }
    const oper = await NetServices.requestPost('alumni/requestfollowmember', oData, true);
    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setAlumniMembers(oper.allAlumniMembers!);
  }

  useEffect(() => {
    populateListMembers();
  }, [])

   
  return (
    <div className="flex flex-col gap-3">
      <h3 className="py-2 font-semibold text-2xl">{languages.alumniMembers}</h3>
      {isLoading && <div className="flex items-center justify-center mb-3 py-5">
      <span className="loading loading-dots loading-xs"></span>
      </div>}
      {(!isLoading && serverError) && 
        <div className="flex flex-col justify-center items-center">
          <h3 className="font-bold text-sm py-2 mb-2">{languages.serverError}</h3>
          <LinkAchorRound type="button"
          bgColor="#0b5b13"
            text={languages.refresh}
            onClick={() => populateListMembers()} />
        </div>
      }
      {(!isLoading && !serverError) && 
        <ListOfAlumniMembersComp 
          lstMembers={alumniMembers} 
          requestFollow={requestFollowMelber} />
      }
    </div>
  );
}

const ListOfAlumniMembersComp = ({lstMembers, requestFollow}: {lstMembers: IAlumniMember[], requestFollow: (member: IAlumniMember, status: number) => void}) => {
  return (
    <div className="border border-solid border-slate-300 rounded-sm shadow p-2">
      <div className="flex flex-wrap gap-2 justify-evenly">
        {lstMembers.map((member, idx) => 
          <AlumniMemberWrapper 
          requestFollow={requestFollow}
            member={member} 
            key={idx} />)}
      </div>
    </div>
  );
}

const AlumniMemberWrapper = ({member, requestFollow}: {member: IAlumniMember, requestFollow: (member: IAlumniMember, status: number) => void}) => {
  const imgSrc = !member.member.imagePath ? alumniImg: 
    `${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${member.member.imagePath}`;
  return (
    <div className="w-[150px] rounded-lg flex flex-col border border-slate-300 border-solid">
      <img src={imgSrc} alt="" 
      className="w-full h-[125px] object-cover object-top rounded-t-lg" />
      <div className="py-2 px-1 flex flex-col gap-1">
        <p className="font-semibold leading-3 text-[13px] py-2 text-center whitespace-nowrap">{member.member.userName}</p>
        {member.isMine && 
          <Link to={'/app/main/myprofile'} className="btn btn-sm w-full">
            {languages.view}
          </Link>
        }
        {!member.isMine && 
          <button className={`btn btn-sm w-full ${!member.isConnected && 'btn-primary'}`}
          onClick={() => requestFollow(member, member.isConnected ? 0: 1)}>
            {member.isConnected ? languages.unfollow: languages.follow}
          </button>
        }
        
      </div>
    </div>
  );
}