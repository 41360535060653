/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "src/components/Card";

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { RealTinyMceEditor } from "src/components/tinyeditor/TinyMceEditor";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";
import { IAlumniMessage, IAlumniShortUser, ITagRec } from "src/models/smarttypes";
import NetServices from "src/utils/netservices";
import RecipientFriendField from "src/components/recipientfrient/RecipientFriendField";

const languages = getLanguages();
const appFxs = getFxs();

const defaultMessage: IAlumniMessage = {
  messageContent: "",
  messageDate: new Date(),
  messageKey: "",
  messageSubject: "",
  receiverEmails: [],
  senderEmail: "",
  attachmentFiles: undefined,
};

interface IFileAttach {
  fileDoc: File, 
  fileKey: string,
  fileKeyExt: string,
}

const MessagesNewPage = () => {
  document.title = `${languages.newMessage} | Alumni - ${languages.schoolNameOnly}`;

  const [formData, setFormData] = useState<IAlumniMessage>(defaultMessage);
  const [listAttachts, setLstAttachts] = useState<IFileAttach[]>([]);

  const atttachMentInput = useRef<HTMLInputElement>(null);

  const [recipients, setRecipients] = useState<ITagRec[]>([]);

  const navigate = useNavigate();

  const saveMessage = () => {
    const fData = {...formData};
    fData.attachmentFiles = listAttachts.length === 0 ? []: 
      listAttachts.map(xO => xO.fileKeyExt);

    const htmlData = appFxs.getTextFromHtml(fData.messageContent);

    //content
    if(!htmlData){
      appFxs.showAlert(languages.fieldError, languages.messageContentReq);
      return;
    }
    //subjects
    if(!fData.messageSubject){
      appFxs.showAlert(languages.fieldError, languages.messageSubjectReq);
      return;
    }
    //recipients
    if(fData.receiverEmails.length === 0){
      appFxs.showAlert(languages.fieldError, languages.messageReceipientsReq);
      return;
    }

    finalSaveRecord(fData);
  };
  const finalSaveRecord = async(msgRecord: IAlumniMessage) => {    
    const fData = new FormData();

    fData.append("messageRecord", JSON.stringify(msgRecord));

    listAttachts.forEach((o) => {
      fData.append(o.fileKeyExt, o.fileDoc);
    });

    const operSave = await NetServices.requestPostWithFileAxios(
      "alumni/sendusermessage",
      fData,
      true
    );

    if (!operSave.bReturn) {
      appFxs.showAlert(operSave.msgTitle, operSave.msgBody);
      return;
    }

    navigate('/app/main/messages');
  }




  const attachMoreFiles = () => {
    atttachMentInput.current?.click();
  };
  const afterselectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const newFiles = (Array.from(e.target.files!)).map(o => {
      const ffKey = appFxs.generateUUID();
      const ffName = `${ffKey}.${o.name.split('.').pop()}`;

      const ffFile:IFileAttach = {
        fileDoc: o,
        fileKey: ffKey,
        fileKeyExt: ffName,
      }
      return ffFile
    });

    const filesWithSize = newFiles.filter(o => {
      const sizeD = appFxs.getFileSizeInMbs(o.fileDoc.size);
      const isOk = sizeD <= 20;
      return isOk;
    })

    const finalAtt = [...listAttachts, ...filesWithSize];
    setLstAttachts(finalAtt);
  };

  const onPostContentchanged = (e: string) => {
    setFormData({ ...formData, messageContent: e });
  };

  const onRemove = (fileKey: string) => {
    const finalAtt = [...listAttachts];
    const lst = finalAtt.filter(ofile => ofile.fileKey !== fileKey);
    setLstAttachts(lst);
  };

  

  const addRecipientsToFormData = () => {
    const lstEmails = recipients.map(o => o.id);
    setFormData({...formData, receiverEmails: lstEmails});
  }

  

  const afterRecipientsUpdate = (updates: IAlumniShortUser[]) => {
    if(updates.length === 0){
      setRecipients([]);
      return;
    }

    const lst = updates.map((o) => {
      const bb:ITagRec =  {
        id: o.userEmail,
        text: o.userName,
      }

      return bb;
    });

    setRecipients(lst);
  }
  

  useEffect(() => {
    addRecipientsToFormData();
  }, [recipients]);

  


  //if(isLoading) return <LoadingWaitSpace />

  return (
    <>      
      <div className="flex flex-col gap-3">
        {/* Header */}
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.newMessage}
                </h3>
                <div className="flex gap-1">
                  <input
                    ref={atttachMentInput}
                    type="file"
                    style={{ display: "none" }}
                    onChange={afterselectFiles}
                    multiple={true}
                  />
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={attachMoreFiles}
                  >
                    <i className="fa fa-files-o"></i>
                  </button>
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={saveMessage}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </div>
              </div>
              <p className="mt-1 text-gray-800 dark:text-gray-400">
                {languages.newMessageMsg}
              </p>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        {/* Body */}
        <Card>
          <div className="flex flex-col gap-2">
            <div className="w-full mb-2">
              <label className="form-control block pr-4">
                <div className="label">
                  <span className="label-text">{languages.subject}</span>
                </div>
                <input
                  type="text"
                  placeholder={languages.typeUrSubject}
                  className="input input-bordered w-full ring-1"
                  name="messageSubject"
                  value={formData.messageSubject}
                  onChange={(e) =>
                    setFormData({ ...formData, messageSubject: e.target.value })
                  }
                />
              </label>
            </div>
            
            <div className="w-full mb-2 flex flex-col gap-1">
              <label className="form-control block pr-4 text-black">
                {languages.recipient}
              </label>
              <div className="w-full input input-bordered ring-1 pt-1 mt-2 pl-0 min-h-[3rem]">
                <RecipientFriendField 
                  recipients={recipients}
                  afterRecipientsUpdate={afterRecipientsUpdate}
                   />
              </div>
            </div>

            {listAttachts.length > 0 && (
              <AttachmentAreaZone
                onRemove={onRemove}
                listAttachts={listAttachts}
                onAddMore={attachMoreFiles}
              />
            )}
            {listAttachts.length === 0 && (
              <h3
                className="py-2 font-semibold text-sm flex items-center gap-1 text-blue-400 hover:text-blue-600 cursor-pointer"
                onClick={attachMoreFiles}
              >
                <span>
                  <i className="fa fa-files-o"></i>
                </span>
                {languages.noAttachts}
              </h3>
            )}
            <div className="flex flex-col gap-1">
              <RealTinyMceEditor
                getContent={onPostContentchanged}
                hasToolbar={true} />
            </div>
            <div className="w-full mt-4">
              <LinkAchorRound
                text={languages.save}
                type="button"
                fullWidth={true}
                borderRadius={18}
                onClick={saveMessage}
              />
            </div>

            <div className="mb-8" />
          </div>
        </Card>
      </div>
    </>
  );
};

export default MessagesNewPage;

const AttachmentAreaZone = ({
  listAttachts,
  onRemove,
  onAddMore,
}: {
  listAttachts: IFileAttach[],
  onRemove: (filePos: string) => void,
  onAddMore: () => void,
}) => {
  const lstImageOrVids = listAttachts.filter(o => {
    const ext = o.fileDoc.name.split('.').pop();
    if(!ext) return false;

    return appFxs.isFileOrImageExt(ext);
  });
  const lstNotImageOrVids = listAttachts.filter(o => {
    const ext = o.fileDoc.name.split('.').pop();
    if(!ext) return false;

    return !appFxs.isFileOrImageExt(ext);
  });

  return (
    <div className="flex flex-col gap-1 border border-slate-300 border-solid">
      {lstImageOrVids.length > 0 && (
        <div className="flex flex-wrap py-2 px-2">
          {lstImageOrVids.map((oFile) => (
            <AttachDoctImageOrVideo
              key={oFile.fileKey}
              file={oFile.fileDoc}
              fileKey={oFile.fileKey}
              onRemoveFile={onRemove}
            />
          ))}

          <div
            className="group w-[100px] aspect-video bg-slate-400 relative rounded-[4px] overflow-hidden mr-2 border border-solid border-slate-400"
            style={{
              backgroundSize: "cover",
            }}
            onClick={() => {}}
          >
            <div className="absolute right-0 top-0 w-full h-full cursor-pointer text-blue-500 hover:text-blue-700 flex items-center justify-center"
              title={languages.addMoreFiles}
              onClick={onAddMore}>
              <i className="fa fa-plus-circle fa-2x"></i>
            </div>
          </div>
        </div>
      )}
      {lstNotImageOrVids.length > 0 && (
        <div className="flex flex-wrap py-2 px-2">
          {lstNotImageOrVids.map((oFile) => (
            <AttachDocuemntOther
              key={oFile.fileKey}
              file={oFile.fileDoc}
              fileKey={oFile.fileKey}
              onRemoveFile={onRemove}
            />
          ))}
            <span
              className="group h-[30px] w-[30px] flex justify-center items-center cursor-pointer rounded-full border border-solid border-slate-400 bg-slate-300 hover:bg-white"
              title={languages.addMoreFiles}
              onClick={onAddMore}
            >
              <i className="fa fa-plus-circle text-zinc-800 group-hover:text-zinc-950 hover:text-black"></i>
            </span>          
        </div>
      )}
    </div>
  );
};

interface IDocumentViewerProps{
  fileKey: string;
  file: File;
  onRemoveFile: (fileKey: string) => void;
}


const AttachDoctImageOrVideo = ({file, fileKey, onRemoveFile}: IDocumentViewerProps) => {
  const ext = file.name.split('.').pop()!;

  const isImage = appFxs.isFileImageExt(ext);
  const isvideo = appFxs.isFileVideoExt(ext);

  return (
    <>
      {isImage && 
        <AttachDocumentImg 
          file={file} 
          fileKey={fileKey} 
          onRemoveFile={onRemoveFile} />
      }
      {isvideo && 
        <AttachDocuemntVideo 
          file={file} 
          fileKey={fileKey} 
          onRemoveFile={onRemoveFile} />
      }
    </>
  );
}
const AttachDocumentImg = ({
  file,
  fileKey,
  onRemoveFile,
}: IDocumentViewerProps) => {
  const [fileImageUrlBg, setFileImageUrlBg] = useState<string>("");

  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      const imageURL = reader.result!;
      const fBg = `url(${imageURL})`;
      setFileImageUrlBg(fBg);
    },
    false
  );
  if (file) {
    reader.readAsDataURL(file);
  }

  return (
    <div
      className="group w-[100px] aspect-video bg-slate-400 relative rounded-[4px] overflow-hidden mr-2 border border-solid border-slate-400"
      style={{
        backgroundImage: fileImageUrlBg,
        backgroundSize: "cover",
      }}
    >
      <div className="hidden group-hover:flex absolute right-1 top-1 w-5 h-5 items-center justify-center cursor-pointer text-blue-500 hover:text-blue-700 rounded-full bg-gray-400 hover:bg-white"
        onClick={() => onRemoveFile(fileKey)}>
        <i className="fa fa-times"></i>
      </div>
    </div>
  );
};
const AttachDocuemntVideo = ({
  file,
  fileKey,
  onRemoveFile,
}: IDocumentViewerProps) => { 
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);

  useEffect(() => {
    setMediaUrl(URL.createObjectURL(file))
  }, []);


  return (
    <div
      className="group w-[100px] aspect-video bg-slate-400 relative rounded-[4px] overflow-hidden mr-2 border border-solid border-slate-400 before:block before:absolute before:bg-black before:bg-opacity-25 before:z-[1] before:w-full before:h-full before:top-0 before:left-0"
      style={{
        backgroundSize: "cover",
      }}
    >
      {mediaUrl && <video src={mediaUrl!} 
        autoPlay={true}
        muted={true}
        className={`absolute top-0 left-0 w-full h-full -z-[0]`}
         />}
      <div className="hidden group-hover:flex absolute right-1 top-1 w-5 h-5 items-center justify-center cursor-pointer text-blue-500 hover:text-blue-700 rounded-full bg-gray-400 hover:bg-white z-[3]"
      onClick={() => onRemoveFile(fileKey)}>
        <i className="fa fa-times"></i>
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-[2]">
        <i className="fa fa-play-circle fa-2x text-black text-opacity-25"></i>
      </div>
    </div>
  );
};
const AttachDocuemntOther = ({
  file,
  fileKey,
  onRemoveFile,
}: IDocumentViewerProps) => {
  const { name } = file;
  const ext = "." + name.split(".").pop()!;

  return (
    <div className="mr-2 mb-2 group text-gray-500 flex items-center text-sm gap-1 py-1 px-1 rounded-[16px] border border-solid border-slate-400">
      <span className="mr-1 w-5 h-5 rounded-full border border-solid border-slate-400 flex items-center justify-center">
        <i className={`${appFxs.filleExtCss(ext)}`}></i>
      </span>
      <span>{appFxs.shortenTextFileName(name)}</span>
      <span
        className="ml-1 text-gray-400 group-hover:text-gray-600 cursor-pointer w-5 h-5 rounded-full border border-solid border-slate-400 bg-slate-300 hover:bg-slate-400 flex items-center justify-center hover:text-white"
        onClick={() => onRemoveFile(fileKey)}
      >
        <i className="fa fa-times"></i>
      </span>
    </div>
  );
};
