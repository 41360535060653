/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';



import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import Card from 'src/components/Card';
import { ImHistory, ImPlus } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as UndeleteSvg} from 'src/assets/images/svg/undelete.svg'


import moment from 'moment';
import NetServices from 'src/utils/netservices';
import { IAlMessageInbox } from 'src/models/smarttypes';


const languages = getLanguages();
const appFxs = generalFxs();

interface IMessageAndAction {
  msg: IAlMessageInbox,
  action: 'read' | 'delete' | 'preview' | 'undelete'
}

const MessagesPage = () => {
  document.title = `${languages.messages} | Alumni - ${languages.schoolNameOnly}`;
  const [isLoading, setIsLoading] = useState(true);
  const [lstMessageInbox, setLstMsgInbox] = useState<IAlMessageInbox[]>([]);
  const [inboxFiltMsgs, setInboxFiltMsgs] = useState<IAlMessageInbox[]>([]);

  const [typeMsg, setTypeMsg] = useState('0');

  const bLoaded = useRef(false);

  const navigate = useNavigate();

  
  const composeNewMessage = () => {
    navigate('/app/main/messages/new');
  }
  const refreshList = async (bAjax: boolean = false) => {
    const operation = await NetServices.requestGet('alumni/getlistofuserrelatedmessages', bAjax);

    if(!operation.bReturn){
      await appFxs.showAlert(operation.msgTitle, operation.msgBody);
      navigate('/app/main/dashboard')
      return;
    }

    setLstMsgInbox(operation.listOfAlMessageInbox!);
    setIsLoading(false);
  }
  const filterMessageByType = () => {
    const f: 'inbox'|'sent'|'deleted' = typeMsg === '0' ? 'inbox':
      typeMsg === '1' ? 'sent':
      typeMsg === '2' ? 'deleted':
      'inbox';  
    const lst = lstMessageInbox.filter(o => {
      return o.status === f;
    });

    setInboxFiltMsgs(lst);
  }

  const afterReadMsg = (msg: IMessageAndAction) => {
    if(msg.action === 'delete') {
      deleteMessage(msg.msg);
    }

    if(msg.action === 'undelete') {
      unDeleteMessage(msg.msg);
    }

    if(msg.action === 'read') {
      setMessageAsRead(msg.msg);
    }
    if(msg.action === 'preview') {
      navigate(`/app/main/messages/preview/${msg.msg.messageRecord.messageKey}`)
    }
  }

  const deleteMessage = async (msg: IAlMessageInbox,) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteMessage);
    if(!bAsk.isConfirmed)return;

    const oData = {
      messageKey: msg.messageRecord.messageKey,
      messageStatus: 1,
      reloadList: true,
    }
    const operDelete = await NetServices.requestPost('alumni/setdeletestatusofmessage', oData, true);
    if(!operDelete.bReturn){
      appFxs.showAlert(operDelete.msgTitle, operDelete.msgBody);
      return;
    }

    setLstMsgInbox(operDelete.listOfAlMessageInbox!);
  }

  const unDeleteMessage = async (msg: IAlMessageInbox,) => {
    const bAsk = await appFxs.showConfirm(languages.undelete, languages.undeleteMessage);
    if(!bAsk.isConfirmed)return;

    const oData = {
      messageKey: msg.messageRecord.messageKey,
      messageStatus: 0,
      reloadList: true,
    }
    const operDelete = await NetServices.requestPost('alumni/setdeletestatusofmessage', oData, true);
    if(!operDelete.bReturn){
      appFxs.showAlert(operDelete.msgTitle, operDelete.msgBody);
      return;
    }

    setLstMsgInbox(operDelete.listOfAlMessageInbox!);
  }

  const setMessageAsRead = async (msg: IAlMessageInbox) => {
    const oData = {
      messageKey: msg.messageRecord.messageKey,
      messageStatus: !msg.isRead ? 1: 0,
      reloadList: true,
    }

    const operDelete = await NetServices.requestPost('alumni/setreadstatusofmessage', oData, true);
    if(!operDelete.bReturn){
      appFxs.showAlert(operDelete.msgTitle, operDelete.msgBody);
      return;
    }

    setLstMsgInbox(operDelete.listOfAlMessageInbox!);
  }
  

  useEffect(() => {
    if(bLoaded.current)return;
    refreshList(false);
    bLoaded.current = true;
  }, []);

  useEffect(() => {
    filterMessageByType();
  }, [typeMsg, lstMessageInbox])


  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.messages}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={composeNewMessage}
                >
                  <ImPlus />
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                  onClick={() => refreshList(true)}
                >
                  <ImHistory />
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.messagesMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* Body */}
      <Card>
        <div className="flex items-center justify-between mb-3">
          <p className="text-2xl font-bold mb-2 text-black">
            {languages.messages}
          </p>

          <div className="join">
            <input
              className="join-item btn"
              type="radio"
              name="typeMsg"
              aria-label={languages.inbox}
              value={"0"}
              checked={typeMsg === "0"}
              onChange={() => setTypeMsg("0")}
            />
            <input
              className="join-item btn"
              type="radio"
              name="typeMsg"
              aria-label={languages.sent}
              value={"1"}
              checked={typeMsg === "1"}
              onChange={() => setTypeMsg("1")}
            />
            <input
              className="join-item btn"
              type="radio"
              name="typeMsg"
              aria-label={languages.deleted}
              value={"2"}
              checked={typeMsg === "2"}
              onChange={() => setTypeMsg("2")}
            />
          </div>
        </div>
        <div className="">
          <div className="table-content-messages w-full divide-y border divide-gray-500 bg-white max-h-[300px] overflow-y-auto">
            {/* loading */}
            {isLoading && (
              <div className="py-4 text-center bg-gray-300">
                <span className="text-black block">
                  <i className="fa fa-spin fa-spinner fa-3x"></i>
                </span>
                <h4 className="text-black my-4 font-bold text-2xl">
                  {languages.loadingMessages}
                </h4>
              </div>
            )}

            {/* no record */}
            {!isLoading && inboxFiltMsgs.length === 0 && (
              <div className="py-4 text-center bg-gray-300">
                <span className="block text-black font-semibold text-lg">
                  {languages.noMsgFnd}
                </span>
                <div className="flex items-center justify-center py-5">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={composeNewMessage}
                  >
                    <ImPlus />
                  </button>
                </div>
              </div>
            )}
            {/* List */}
            {inboxFiltMsgs.map((oMessage, idx) => (
              <TableMessageRowBlog inboxMsg={oMessage} afterReadMsg={afterReadMsg} key={idx} />
            ))}
          </div>          
        </div>
      </Card>
    </div>
  );
}

export default MessagesPage


const TableMessageRowBlog = ({inboxMsg, afterReadMsg}:{inboxMsg: IAlMessageInbox, afterReadMsg: (msg: IMessageAndAction) => void}) => {
  return (
    <div className="group flex w-full text-black py-2 hover:bg-slate-200 cursor-pointer hover:shadow-lg relative">
      <ToolsMessageSetts inboxMsg={inboxMsg} afterReadMsg={afterReadMsg} />

      <div className='basis-[5%] force-1 flex items-center justify-center'>
        <div className='flex items-center justify-center'>
          <input type='checkbox' />
        </div>
      </div>
      <div className={`basis-[25%] force-1 flex justify-start items-center text-sm ${(!inboxMsg.uOwner && !inboxMsg.isRead) && 'font-semibold'}`}>
        <SenderReceiverComp inboxMsg={inboxMsg} />
      </div>
      <div className={`basis-[50%] force-1 flex justify-start items-center text-sm ${(!inboxMsg.uOwner && !inboxMsg.isRead) && 'font-semibold'}`}>
        <div className='py-1 pl-4 pr-2'>
          <div className='flex items-center'>
            {inboxMsg.messageRecord.attachmentFiles?.length! > 0 && 
              <span className='mr-2'>
                <i className='fa fa-paperclip'></i>
              </span>
            }
            <span>{inboxMsg.messageRecord.messageSubject}</span>
          </div>
        </div>
      </div>
      <div className={`basis-[20%] force-1 flex justify-end items-center text-sm ${(!inboxMsg.uOwner && !inboxMsg.isRead) && 'font-semibold'} `}>
        <div className='py-1 px-2'>{moment(inboxMsg.messageRecord.messageDate).format('MMM DD YYYY')}</div>
      </div>
    </div>
  );
}

const SenderReceiverComp = ({inboxMsg}:{inboxMsg: IAlMessageInbox}) => {
  const {uOwner} = inboxMsg;

  return (
    <div className='py-1 px-2'>
      {!uOwner && <span>{inboxMsg.senderRecord?.userName}</span>}
      {uOwner &&
        <span>
          <span>{languages.to}</span>:&nbsp;
          {inboxMsg.messageReceivers[0].userName}
        </span>
      }
    </div>
  );
}
const ToolsMessageSetts = ({inboxMsg, afterReadMsg}:{inboxMsg: IAlMessageInbox, afterReadMsg: (msg: IMessageAndAction) => void}) => {
  return (
    <div className='hidden group-hover:flex  items-center absolute right-0 top-0 w-auto h-full px-2 bg-slate-200 transition-[all]'>
      {(!inboxMsg.uOwner && inboxMsg.status !== 'deleted') && 
        <button className='mr-1 p-2 hover:bg-slate-600 hover:text-white'
          title={languages.delete}
          onClick={() => afterReadMsg({msg:inboxMsg, action:'delete'})}>
          <i className='fa fa-trash'></i>
        </button>
      }
      {inboxMsg.status === 'deleted' && 
        <button className='mr-1 p-2 hover:bg-slate-600 hover:text-white'
          title={languages.undelete}
          onClick={() => afterReadMsg({msg:inboxMsg, action:'undelete'})}>
          {/* <i className='fa fa-trash-o line-through'></i> */}
          <UndeleteSvg className='hover:fill-white' width={18} height={18} />
        </button>
        }
      <button className='mr-1 p-2 hover:bg-slate-600 hover:text-white'
        title={languages.preview}
        onClick={() => afterReadMsg({msg:inboxMsg, action:'preview'})}>
        <i className='fa fa-eye'></i>
      </button>
      {(!inboxMsg.uOwner && !inboxMsg.isRead) && 
        <button className='mr-1 p-2 hover:bg-slate-600 hover:text-white'
          title={languages.markAsRead}
          onClick={() => afterReadMsg({msg:inboxMsg, action:'read'})}>
          <i className='fa fa-book'></i>
        </button>
      }
      {(!inboxMsg.uOwner && inboxMsg.isRead) && 
        <button className='mr-1 p-2 hover:bg-slate-600 hover:text-white'
          title={languages.markAsUnread}
          onClick={() => afterReadMsg({msg:inboxMsg, action:'read'})}>
          <i className='fa fa-bookmark text-red-700'></i>
        </button>
      }
    </div>
  );
}