/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import alLogo from 'src/assets/images/alumniimg.png';
import { FormInputElt } from "src/components/forminputelt/FormInputElt";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";
import { IFieldRecord } from "src/models/smarttypes";
import appLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { RecaptaField } from "src/components/recaptafield/RecaptaField";
import NetServices from "src/utils/netservices";
import appDeclarations from "src/utils/declarations";

const languages = appLanguages();
const appFxs = generalFxs();

interface IFormError {
  email?: string,
  captcha?: string
};



export const AcctForgotPage = () => {
  document.title = `${languages.forgotPassWord} | ${languages.schoolName}`;
    const navigate = useNavigate();

    const [showServerMsg, setShowServerMsg] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const [formData, setFormData] = useState({email: '', captcha: appFxs.getRandomDigits(5)});
    const [formErrors, setFormErrors] = useState<IFormError>({});
    const [isLoging, setIsLoaing] = useState(false);

    let formRecapVal = '';
    let formXErrors:IFormError = {};

    
  const removeServerError = () => {
    setShowServerMsg(false);
    setServerMessage('');
  }
  const onChange = (e: IFieldRecord) => {
    const fData = {...formData, [e.field]: e.value};
    setFormData(fData);
  }
  const validateFields = () => {
    const finaldata:IFormError = {};

    //email
    if(!formData.email){
      finaldata.email = languages.fldRequired;
    }else if(!appFxs.isEmailValid(formData.email)){
      finaldata.email = languages.emailFormat;
    }

    if(!formRecapVal){
      finaldata.captcha = languages.fldRequired;
    }else if(formRecapVal !== formData.captcha){
        finaldata.captcha = languages.recaptchaError;
    }

    formXErrors = {...finaldata};
    setFormErrors({...finaldata})
  }
  const sendForm = async () => {
    validateFields();
    if(Object.keys(formXErrors).length > 0) return;
    setIsLoaing(true);

    const oData = {
      userEmail: window.btoa(formData.email),
    }
    const oRes = await NetServices.requestPost('alumni/forgotpw', oData); 

    if(!oRes.bReturn){
      setIsLoaing(false);
      setServerMessage(oRes.msgBody);
      setShowServerMsg(true);
      return;
    }

    const awaitingEmail = oRes.awaitingEmail!;
    appFxs.setLocalStorageByKey(appDeclarations.waitingEmailForPw, awaitingEmail);
    
    navigate('/account/wait');
  }
  const changeRecaptchaVal = async (e: string) =>{
      formRecapVal = e;
  }



  return (
    <div className="flex flex-col">
      <div className="l-hearder mb-2">
        <Link to="/" className="flex items-center">
          <img src={alLogo} alt="" className="logo mr-3" />
          <span className="stockix">Alumni</span>
        </Link>
      </div>
      {showServerMsg && (
        <div className="alert-error relative" ng-if="isServerError">
          <a
            className="close-btn grow-0"
            title={languages.close}
            onClick={removeServerError}
            data-toggle="tooltip"
            data-placement="top"
          >
            <i className="fa fa-times-circle"></i>
          </a>
          <span className="grow text-red-500">{serverMessage}</span>
        </div>
      )}

      <form className="login-form mt-3">
        <div className="flex flex-col mt-3">
          <h1 className="tiny_bottom_margin size-17rem">
            {languages.forgotPassWord}
          </h1>
          <p className="py-4">alumni.lacolombiere.ac.rw</p>
          <p className="mb-4 font-semibold">{languages.enterUrEmail}</p>
          <FormInputElt
            type="email"
            name="email"
            value={formData.email}
            placeholder={languages.yourEmail.toLowerCase()}
            errorMessage={formErrors.email}
            onChange={onChange}
            disabled={isLoging}
          />
          <div className="w-full flex justify-end">
            <RecaptaField
              startingValue={formData.captcha}
              onChange={changeRecaptchaVal}
              disabled={isLoging}
            />
          </div>
          {formErrors.captcha &&  
            <div className="pb-2 text-end">
              <span className="text-red-800">{formErrors.captcha}</span>
            </div>
          }

          {isLoging && (
            <div className="flex items-center justify-center py-3">
              <i className="fa fa-spinner fa-spin fa-3x"></i>
            </div>
          )}
          {!isLoging && (
            <LinkAchorRound
              type="button"
              text={languages.reset}
              bgColor="#28a745"
              hoverBgcolor="#218838"
              borderRadius={4}
              onClick={sendForm}
            />
          )}
          {!isLoging && (
            <Link
              className="font-semibold text-blue-500 mt-6 hover:text-blue-700"
              to={"/account/login"}
            >
              {languages.logInstead}
            </Link>
          )}
        </div>
      </form>
    </div>
  );
}
