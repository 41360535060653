import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {ReactComponent as PlusDoct} from 'src/assets/images/svg/plus-doct.svg';
import { WithContext as ReactTags } from 'react-tag-input';

import Card from "src/components/Card";

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { IFinalEventDetail, IFinalEventRecord, IScocialEvtData, ISimpleData, ISocialEvtFileElt, ISocialFileEltRecord, ITagRec } from "src/models/smarttypes";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";
import NetServices from "src/utils/netservices";
import TinyMceEditor from "src/components/tinyeditor/TinyMceEditor";



const languages = getLanguages();
const appFxs = getFxs();



const SocialEventsNewPage = () => {
  document.title = `${languages.newSocialEvent} | Alumni - ${languages.schoolNameOnly}`;

    const [lstEvents, setLstEvents] = useState<ISocialEvtFileElt[]>([]);
    const [formData, setFormData] = useState<IScocialEvtData>({
      eventKey: appFxs.generateUUID(),
      eventTitle: '',
      eventLocation: '',
      evtTags: [],
    });
    const navigate = useNavigate();



    const fileCollector = useRef<HTMLInputElement|null>(null);

    const onSelectFile = () => {
        fileCollector.current!.click();
    }
    const afterReceivingFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!(e.target.files && e.target.files.length > 0))return;
        const files = e.target.files!;

        const tempoLst: ISocialEvtFileElt[] = [...lstEvents];
        
        for (const file of files) {
            if (appFxs.isFileImage(file)) {
              const fileKey = appFxs.generateUUID();

              const newRec: ISocialEvtFileElt = {
                fileKey: fileKey,
                fileName: file.name,
                fileElt: file,
                title: file.name,
                descr: "",
                size: file.size,
                fileExt: file.name.split('.').pop()!,
              };

              tempoLst.push(newRec);
            }
        };

        setLstEvents(tempoLst);
    }
    const onDropfiles = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();

        const tempoLst: ISocialEvtFileElt[] = [...lstEvents];

        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach(item => {
              if (item.kind === "file") {
                const file = item.getAsFile()!;
                if (appFxs.isFileImage(file)) {
                  const fileKey = appFxs.generateUUID();

                  const newRec: ISocialEvtFileElt = {
                    fileKey: fileKey,
                    fileName: file!.name,
                    fileElt: file!,
                    title: file!.name,
                    descr: "",
                    size: file!.size,
                    fileExt: file.name.split('.').pop()!,
                  };

                  tempoLst.push(newRec);
                }
              }
            });
          } else {
            [...ev.dataTransfer.files].forEach((file) => {
              if (appFxs.isFileImage(file)) {
                const fileKey = appFxs.generateUUID();

                const newRec: ISocialEvtFileElt = {
                  fileKey: fileKey,
                  fileName: file.name,
                  fileElt: file,
                  title: file.name,
                  descr: "",
                  size: file.size,
                  fileExt: file.name.split('.').pop()!,
                };

                tempoLst.push(newRec);
              }
            });
          }

          setLstEvents(tempoLst);
    }

    const onElementDelete = (evtKey: string) => {
      const newList = [...lstEvents].filter(o => o.fileKey !== evtKey);
      setLstEvents(newList);
    }
    const onUpdateSingle = (data: ISimpleData) => {
      const index = lstEvents.findIndex(o => o.fileKey === data.eventKey);
      const elt = lstEvents[index];
      if(elt === null)return;

      elt!.descr = data.description;
      elt!.title = data.title;

      const newList = [...lstEvents];
      newList[index]= elt!;


      setLstEvents(newList);
    }

    const handleTagDrag = (tag:ITagRec, currPos:number, newPos:number) => {
      const newTags = formData.evtTags.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setFormData({...formData, evtTags: newTags});
    };
    const handleTagAddition = (tag:ITagRec) => {
      const newTags = [...formData.evtTags, tag];
      setFormData({...formData, evtTags: newTags});
    };
    const handleTagDelete = (i: number) => {
      const newTags = formData.evtTags.filter((_, index) => index !== i);
      setFormData({...formData, evtTags: newTags})
    };




    const saveSocialEvent = () => {
      if(!formData.eventLocation || !formData.eventTitle){
        appFxs.showAlert(languages.fieldError, languages.locTitleReqMsg);
        return;
      }
      if(lstEvents.filter(o => !o.descr).length >0){
        appFxs.showAlert(languages.fieldError, languages.fieldErrorDesc);
        return;
      }
      if(lstEvents.filter(o => !o.title).length >0){
        appFxs.showAlert(languages.fieldError, languages.fieldErrorTitle);
        return;
      }


      const finalData:IFinalEventRecord = {
        eventKey: formData.eventKey,
        eventTitle: formData.eventTitle,
        eventLocation: formData.eventLocation,
        evtTags: formData.evtTags.map(o => o.text),
        details: lstEvents.map(o => {
          const f: IFinalEventDetail = {
            description: o.descr,
            fileKey: o.fileKey,
            fileExtension: o.fileExt,
            fileName: o.fileName,
            fileSize: o.size,
            title: o.title
          }
          return f;
        }),
      }
      

      finalSaveRecord(finalData);
    }

    const finalSaveRecord = async (oRecord: IFinalEventRecord)=> {
      const fData = new FormData();

      fData.append('eventData', JSON.stringify(oRecord));

      lstEvents.forEach(oEvt => {
        fData.append(oEvt.fileKey, oEvt.fileElt);
      })

      const operSave = await NetServices.requestPostWithFileAxios('alumni/saveimagesevents', fData, true);

      if(operSave.bReturn){
        appFxs.showAlert(operSave.msgTitle, operSave.msgBody);
        return;
      }

      navigate('/app/main/socialevents');
    }


  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.newSocialEvent}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={saveSocialEvent}
                >
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.newSocialEventMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* Body */}
      <Card>
        <div className="flex flex-col gap-2">
          {/* Fields */}
          <div className="flex flex-wrap py-3 justify-between">
            <div className="basis-full  md:basis-[49%] mb-3">
              <div className="flex flex-col py-2 pr-3">
                <label className="mb-2 text-black" htmlFor="eventTitle">
                  {languages.eventTitle}
                </label>
                <input
                  id="eventTitle"
                  name="eventTitle"
                  className="input border-solid input-bordered w-full text-black"
                  placeholder={languages.typeEventTitle}
                  value={formData.eventTitle}
                  onChange={e => setFormData({...formData, eventTitle: e.target.value})}
                />
              </div>
            </div>
            <div className="basis-full  md:basis-[49%] mb-3">
              <div className="flex flex-col py-2 pl-3">
                <label className="mb-2 text-black" htmlFor="eventLocation">
                  {languages.location}
                </label>
                <input
                  id="eventLocation"
                  name="eventLocation"
                  className="input border-solid input-bordered w-full text-black"
                  placeholder={languages.typeEventLoc}
                  value={formData.eventLocation}
                  onChange={e => setFormData({...formData, eventLocation: e.target.value})}
                />
              </div>
            </div>
            <div className="basis-full  md:basis-[49%] mb-3">
              <div className="flex flex-col py-2 pl-3">
                <label className="mb-2 text-black">
                  {languages.tags}
                </label>
                <ReactTags 
                  tags={formData.evtTags}
                  handleDelete={handleTagDelete}
                  handleAddition={handleTagAddition}
                  handleDrag={handleTagDrag}
                  inputFieldPosition="inline"
                  placeholder={languages.tagHolderText}
                />
              </div>
            </div>
          </div>

          {/* File Selector */}
          <div
            className="image-wrapper realative"
            onClick={onSelectFile}
            onDrop={onDropfiles}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="flex min-h-[40vh]" style={{ transform: "none" }}>
              <div className="bg-background text-foreground relative rounded-lg border-2 backdrop-blur-[2px] before:pointer-events-none before:absolute before:-inset-[2px] before:rounded-lg before:p-[2px] before:[background:linear-gradient(120deg,#006400)] dark:shadow-[0] focus-visible:ring-ring ring-offset-background flex flex-1 cursor-pointer flex-col items-center justify-center focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 aria-disabled:pointer-events-none aria-disabled:opacity-60 min-h-[40vh]">
                <div className="text-muted-foreground/40 flex flex-col items-center justify-center p-6">
                  <div className="flex">
                    <div className="doct-interface interface-left aspect-[3/4] z-10 flex flex-col gap-y-1 w-24 origin-top-right -rotate-[22deg] py-4 px-2">
                      <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                      <div className="bg-[#04f771] h-2 w-5/6 rounded-[2px]"></div>
                      <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                    </div>
                    <div className="doct-interface interface-middle w-24 z-20 flex aspect-[3/4] flex-col items-center justify-center gap-y-1 rounded-lg x-2 py-4 backdrop-blur-sm">
                      <PlusDoct
                        className="w-12 h-12 block"
                        fill="#04f771"
                        stroke="#04f771"
                      />
                    </div>
                    <div className="doct-interface interface-right z-10 flex aspect-[3/4] w-24 origin-top-left rotate-[22deg] flex-col gap-y-1 rounded-lg border px-2 py-4 backdrop-blur-sm">
                      <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                      <div className="bg-[#04f771] h-2 w-5/6 rounded-[2px]"></div>
                      <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                    </div>
                  </div>
                  <input
                    accept="image/png, image/jpeg"
                    type="file"
                    tabIndex={-1}
                    style={{ display: "none" }}
                    ref={fileCollector}
                    multiple={true}
                    onChange={afterReceivingFiles}
                  />
                  <p className="group-hover:text-foreground text-muted-foreground mt-8 font-medium">
                    Add a document
                  </p>
                  <p className="text-muted-foreground/80 mt-1 text-sm ">
                    Drag & drop your document here.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* File details */}
          <div className="mt-5">
            <div className="flex flex-wrap justify-between">
              {lstEvents.map((oEvents, idx) => (
                <SocialFileEltRecord
                  eventData={oEvents}
                  key={idx}
                  onDelete={onElementDelete}
                  onUpdateSingle={onUpdateSingle}
                />
              ))}
            </div>
          </div>

          {/* Save button */}
          <LinkAchorRound type="button" 
            fullWidth={true} 
            text={languages.save}
            bgColor="#0b5b13"
            borderRadius={10}
            onClick={saveSocialEvent} />
        </div>
      </Card>
    </div>
  );
}

export default SocialEventsNewPage;



const SocialFileEltRecord = ({ eventData, onDelete, onUpdateSingle }: ISocialFileEltRecord) => {

  const { fileElt, title, descr, fileKey } = eventData;

  const onDeleteEvent = async () => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteSocEventDet);
    if(!bAsk.isConfirmed)return;
    onDelete(eventData.fileKey);
  }
  


  const url = window.URL || window.webkitURL;
  const srcImg = url.createObjectURL(fileElt);

  const [formData, setFormData] = useState<ISimpleData>({
    title: title,
    description: descr,
    eventKey: fileKey,
  });

  const onEditorChangeValue = (e: string) => {
    setFormData({...formData, description: e});
  }



  return (
    <div className="card card-side bg-base-100 shadow-xl w-full h-full mb-6 flex items-stretch">
      <figure className="w-[200px] relative">
        <img src={srcImg} 
            alt="Movie" 
            className="absolute top-0 right-0 bottom-0 left-0 object-cover w-full h-full" />
      </figure>
      <div className="card-body">
        <div className="card-title mb-2 flex flex-col items-start">
            <label htmlFor="eltTitle" className="text-black">{languages.title}</label>
            <input type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={e => setFormData({...formData, title: e.target.value})}
                className="border border-slate-300 border-solid w-full py-2 px-3 rounded-lg text-black text-sm"
                />
        </div>
        <div className="editor-w card-title mb-2 flex flex-col items-start">
            <label htmlFor="eltDescr" className="text-black">{languages.description}</label>
            <TinyMceEditor 
              hasToolbar={false} 
              getContent={onEditorChangeValue} />
        </div>
        <div className="card-actions justify-end gap-2">
          <button className="btn btn-sm btn-primary flex gap-2"
            onClick={() => onUpdateSingle(formData)}>
            <i className="fa fa-save"></i>
            <span>{languages.save}</span>
          </button>
          <button className="btn btn-sm btn-warning flex gap-2"
            onClick={onDeleteEvent}>
            <i className="fa fa-trash"></i>
            <span className="">{languages.delete}</span>
          </button>
        </div>
      </div>
    </div>
  );
};