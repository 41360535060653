//import styles from 'src/components/searchwebimages/SearchWebImages.module.css';

import { useState } from "react"
import { IPexelsImage } from "src/models/smarttypes";

import NetServices from "src/utils/netservices";
import generalFxs from 'src/utils/general_fx';
import appLanguaes from 'src/utils/language';

const appFxs = generalFxs();
const languages = appLanguaes();

interface ISearchWebImages {
    onSelectedImg: (oImg: IPexelsImage) => void,
}
interface IPexelsImageAreaComp{
    image: IPexelsImage,
    onSelectedImage: (oImg: IPexelsImage) => void
}


const SearchWebImages = ({ onSelectedImg }: ISearchWebImages) => {
  const [lstImages, setListImages] = useState<IPexelsImage[]>([]);
  const [keyWord, setKeyWord] = useState("");
  const [searchPage, setSearchPage] = useState(0);

  const selectImages = async (page: number) => {
    if (!keyWord) return;
    const images = await NetServices.requestPexelsImage(keyWord, page);

    if (!images.bReturn) {
      appFxs.showAlert(images.msgTitle, images.msgBody);
      return;
    }

    const finalImageReq = images.pexelsRequest!;
    setSearchPage(finalImageReq.page);
    setListImages(finalImageReq.photos);
  };
  const removeContent = () => {
    setKeyWord("");
    setSearchPage(0);
    setListImages([]);
  };
  const searchMore = async () => {
    if (!keyWord) return;
    const images = await NetServices.requestPexelsImage(
      keyWord,
      searchPage + 1
    );

    if (!images.bReturn) {
      appFxs.showAlert(images.msgTitle, images.msgBody);
      return;
    }

    const finalImageReq = images.pexelsRequest!;
    setSearchPage(finalImageReq.page);
    const allPhotos = [...lstImages, ...finalImageReq.photos];
    setListImages(allPhotos);
  };
  const checkKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isRet = e.key === "Enter" && keyWord;
    if (isRet) selectImages(1);
  };

  return (
    <div className="flex flex-col">
      <div className="search-zone  flex items-center gap-3 border-b border-solid border-slate-400 pb-3">
        <input
          type="text"
          placeholder={languages.typeHere}
          className="input input-bordered flex-1"
          onChange={(e) => setKeyWord(e.target.value)}
          value={keyWord}
          onKeyPress={checkKeyPress}
        />

        {keyWord && (
          <button onClick={removeContent}>
            <i className="fa fa-times text-slate-600 hover:text-black"></i>
          </button>
        )}
        <button onClick={() => selectImages(1)}>
          <i className="fa fa-search text-slate-600 hover:text-black"></i>
        </button>
      </div>
      <div className="mt-2 pt-3 max-h-[300px] flex flex-col overflow-y-scroll">
        {lstImages.length === 0 && (
          <div className="flex-1 flex items-center justify-center">
            No selection has been made
          </div>
        )}
        {lstImages.length > 0 && (
          <div className="flex-1 grid grid-cols-3 gap-4">
            {lstImages.map((oPhoto) => (
              <PexelsImageAreaComp
                image={oPhoto}
                key={oPhoto.id}
                onSelectedImage={onSelectedImg}
              />
            ))}
          </div>
        )}
        <div className="flex items-center justify-between">
          <span>{}</span>
          {searchPage > 0 && (
            <button className="mr-3 p-2" onClick={searchMore}>
              <i className="fa fa-history text-slate-600 hover:text-black"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchWebImages

const PexelsImageAreaComp = ({image, onSelectedImage}:IPexelsImageAreaComp) => {
    return (
        <div className="border border-solid border-slate-400 rounded-lg aspect-video cursor-pointer"
            style={{
                backgroundImage: `url(${image.src.landscape})`,
                backgroundSize: 'cover',
            }}
            onClick={() => onSelectedImage(image)}>                         
        </div>
    );
}