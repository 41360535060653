import { IPexelsImage } from 'src/models/smarttypes';
import WiseModal from '../modalportal/WiseModal';
import SearchWebImages from '../searchwebimages/SearchWebImages';

interface ISearchWebImages {
    isOpen:boolean, 
    titleBox: string,
    dialogWidth?: number,
    onClose: (oImg: IPexelsImage| null) => void,
}

const SearchWebUmagesV2 = ({isOpen, titleBox,dialogWidth, onClose}: ISearchWebImages) => {

    if(!isOpen) return null;

    return (
      <WiseModal 
        isOpen={isOpen} 
        onClose={() => onClose(null)} 
        titleBox={titleBox} 
        width={dialogWidth ?? 450}>
        <SearchWebImages onSelectedImg={onClose}  />
      </WiseModal>
    );
}

export default SearchWebUmagesV2;

