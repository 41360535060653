import { Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { AccountLayout } from "./pages/layouts/AccountLayout";
import { AcctLoginPage } from "./pages/account/AcctLoginPage";
import { AcctResetPage } from "./pages/account/AcctResetPage";
import { AcctNewPassword } from "./pages/account/AcctNewPassword";
import { AcctForgotPage } from "./pages/account/AcctForgotPage";
import { AcctWaitForReset } from "./pages/account/AcctWaitForReset";
import { AcctSetNewPwPage } from "./pages/account/AcctSetNewPwPage";
import { AppLayout } from "./pages/layouts/AppLayout";
import { LoadingApp } from "./pages/app/LoadingApp";
import AppMainLayout from "./pages/layouts/AppMainLayout";
import DashboardPage from "./pages/app/DashboardPage";
import ListBlogsPage from "./pages/app/ListBlogsPage";
import EventsPage from "./pages/app/EventsPage";
import MessagesPage from "./pages/app/MessagesPage";
import SocialEventsPage from "./pages/app/SocialEventsPage";
import MyProfilePage from "./pages/app/MyProfilePage";
import NewBlogPostPage from "./pages/app/NewBlogPostPage";
import PreviewBlogPage from "./pages/app/PreviewBlogPage";
import PreviewBlogComments from "./pages/app/PreviewBlogComments";
import SocialEventsNewPage from "./pages/app/SocialEventsNewPage";
import MessagesNewPage from "./pages/app/MessagesNewPage";
import MessagesPreviewPage from "src/pages/app/MessagesPreviewPage";
import SocialEventsPreviewPage from "./pages/app/SocialEventsPreviewPage";
import AppSettings from "./pages/app/AppSettings";


function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/account" element={<AccountLayout />}>        
        <Route index element={<AcctLoginPage />} />
        <Route path="login" element={<AcctLoginPage />} />
        <Route path="setnewpw/:setkey" element={<AcctSetNewPwPage />} />

        <Route path="forgot" element={<AcctForgotPage />} />
        <Route path="wait" element={<AcctWaitForReset />} />
        <Route path="reset/:resetKey" element={<AcctResetPage />} />
        <Route path="newpw" element={<AcctNewPassword />} />
      </Route>
      <Route path="/app" element={<AppLayout />}>
        <Route index element={<LoadingApp />} />
        <Route path="/app/main" element={<AppMainLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="blog" element={<ListBlogsPage />} />
          <Route path="blog/new-blog-post/:postEltKey" element={<NewBlogPostPage />} />
          <Route path="blog/preview-post/:postEltKey" element={<PreviewBlogPage />} />
          <Route path="blog/preview-comments/:postEltKey" element={<PreviewBlogComments />} />

          <Route path="socialevents" element={<SocialEventsPage />} />
          <Route path="socialevents/new" element={<SocialEventsNewPage />} />
          <Route path="socialevents/preview-event/:eventKey" element={<SocialEventsPreviewPage />} />

          <Route path="events" element={<EventsPage />} />

          <Route path="messages" element={<MessagesPage />} />
          <Route path="messages/new" element={<MessagesNewPage />} />
          <Route path="messages/preview/:messageKey" element={<MessagesPreviewPage />} />

          <Route path="myprofile" element={<MyProfilePage />} />
          <Route path="appsettings" element={<AppSettings />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
