
import getLanguages from 'src/utils/language';
import generalFx from 'src/utils/general_fx';
import appDecl from 'src/utils/declarations';

import Card from 'src/components/Card';
import { ImHistory } from 'react-icons/im';
import { useEffect, useState } from 'react';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';
import { LinkAchorRound } from 'src/components/linkanchorround/LinkAchorRound';
import Swal from 'sweetalert2';



const languages = getLanguages();
const appFxs = generalFx();

const oLang = appFxs.getLocalStorageByKey(appDecl.currentLang, 'en');
const oYear = appFxs.getLocalStorageByKey(appDecl.currentYearbook, '2024');
const oItList = appFxs.getLocalStorageByKey(appDecl.itemsPerPage, '15');

const AppSettings = () => {
  document.title = `${languages.settings} | Alumni - ${languages.schoolNameOnly}`;
  const [appLang, setLang] = useState(oLang);
  const [appYear, setYear] = useState(oYear);
  const [itemsPerLst, setPerList] = useState(oItList);

  const [isLoading, setLoading] = useState(true);
  const cYear = (new Date()).getFullYear();
  const appYears = Array.from({length: 10}).map((_, i) => cYear-(10 - (i+1)));

  const refreshSettings = async () => {
    const vLang = appFxs.getLocalStorageByKey(appDecl.currentLang, 'en');
    const vYear = appFxs.getLocalStorageByKey(appDecl.currentYearbook, '2024');

    setLang(vLang);
    setYear(vYear);
  }

  const saveSettings = async () => {
    appFxs.showAjaxLoader(`${languages.savingSettings}...`);

    appFxs.setLocalStorageByKey(appDecl.currentLang, appLang);
    appFxs.setLocalStorageByKey(appDecl.currentYearbook, appYear); 
    appFxs.setLocalStorageByKey(appDecl.itemsPerPage, itemsPerLst);

    setTimeout(() => {
      Swal.close();
      document.location.reload();
    }, 3000);
  }

  useEffect(() => {
    refreshSettings();
    setLoading(false);
  }, []);

  if(isLoading) return <LoadingWaitSpace />
  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <h3 className="text-lg font-bold text-gray-800">
              {languages.settings}
            </h3>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.settingsMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2">
            <button
              type="button"
              className="flex items-center px-2 py-3 rounded-md font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
              onClick={refreshSettings}
            >
              <ImHistory />
            </button>
          </div>
        </div>
      </Card>
      {/* Body */}
      <Card>
        {/* Form */}
        <div className='py-2 flex flex-col'>
          <label className='flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3'>
            <span className='w-full mb-1 md:w-[150px]'>{languages.language}</span>
            <select className="select select-primary border border-solid w-full md:flex-1"
              value={appLang}
              onChange={e => setLang(e.target.value)}>
              <option value={'en'}>{languages.english_m}</option>
              <option value={'fr'}>{languages.french_m}</option>
            </select>
          </label>

          <label className='flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3'>
            <span className='w-full mb-1 md:w-[150px]'>{languages.year}</span>
            <select className="select select-primary border border-solid w-full md:flex-1"
              value={appYear}
              onChange={e => setYear(e.target.value)}>
              {appYears.map((x, idx) => <option key={idx} value={x.toString()}>{x.toString()}</option>)}
            </select>
          </label>

          <label className='flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3'>
            <span className='w-full mb-1 md:w-[150px]'>{languages.itemsPerList}</span>
            <select className="select select-primary border border-solid w-full md:flex-1"
              value={itemsPerLst}
              onChange={e => setPerList(e.target.value)}>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
              <option value='25'>25</option>
              <option value='30'>30</option>
              <option value='35'>35</option>
              <option value='40'>40</option>
              <option value='45'>45</option>
              <option value='50'>50</option>
            </select>
          </label>

          <LinkAchorRound 
            type='button' 
            text={languages.save}
            bgColor='#0b5b13'
            onClick={saveSettings}/>
        </div>
      </Card>
    </div>
  )
}

export default AppSettings