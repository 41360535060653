/* eslint-disable react-hooks/exhaustive-deps */
import { ImHistory, ImPlus } from "react-icons/im";
import {Link, useNavigate} from 'react-router-dom';


import moment from "moment";
import Card from "src/components/Card";
import { IBlogPostCommentsPost, IBlogRecord, IPublishStatusComp, ITableRowBlog } from "src/models/smarttypes";

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';

import { useEffect, useState } from "react";
import LoadingWaitSpace from "src/components/LoadingWaitSpace";
import NetServices from "src/utils/netservices";

const languages = getLanguages();
const appFxs = generalFxs();

const ListBlogsPage = () => {
  document.title = `${languages.listBlogs} | Alumni - ${languages.schoolNameOnly}`;
  const [isLoading, setIsLoading] = useState(true);
  const [listOfBlogs, setListOfBlogs] = useState<IBlogRecord[]>([]);

  
  const lst = listOfBlogs;
  const navigate = useNavigate();

  const refreshList = async (bAjax: boolean) => {
    const oOper = await NetServices.requestGet('alumni/getlistofrelatedblogs', bAjax);
    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setListOfBlogs(oOper.lstOfBlogRecords!);
    setIsLoading(false);
  }
  const addNewPost = () => {
    navigate('/app/main/blog/new-blog-post/newblogpost');
  }

  const afterPublish = (lst: IBlogRecord[]) => {
    setListOfBlogs(lst);
  }



  useEffect(() => {
    refreshList(false);
  }, []);

  if(isLoading) return <LoadingWaitSpace />
  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.blogs}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={addNewPost}
                >
                  <ImPlus />
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                  onClick={() => refreshList(true)}
                >
                  <ImHistory />
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.listBlogsMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>
      {/* */}
      {/* Body */}
      <Card>
        <p className="text-2xl font-bold mb-2 text-black">
          {languages.listBlogs}
        </p>
        <div className="flex flex-wrap gap-3">
          <div className="overflow-x-auto w-full">
            <table className="table table-zebra">
              <thead>
                <tr>
                  <th>{languages.title}</th>
                  <th>{languages.date}</th>
                  <th>{languages.tags}</th>
                  <th>{languages.comments}</th>
                  <th>{languages.status}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && <tr>
                  <td colSpan={5}>
                    <div className="py-4 text-center bg-gray-300">
                      <span className="text-black block">
                        <i className="fa fa-spin fa-spinner fa-3x"></i>
                      </span>
                      <h4 className="text-black my-4 font-bold text-2xl">
                        {languages.loadingBlogs}
                      </h4>
                    </div>
                  </td>
                </tr>}
                {(!isLoading && listOfBlogs.length === 0) && <tr>
                  <td colSpan={5}>
                    <div className="py-4 text-center bg-gray-300">
                      <span className="block text-black font-semibold text-lg">
                        {languages.noblogFnd}
                      </span>
                      <div className="flex items-center justify-center py-5">
                        <button
                          type="button"
                          className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                          onClick={addNewPost}
                        >
                          <ImPlus />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>}

                {lst.map((oBlog, idx) => (
                  <TableRowBlog 
                    postRecord={oBlog} 
                    afterPublish={afterPublish}
                    key={idx} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ListBlogsPage


const TableRowBlog = ({postRecord, afterPublish}: ITableRowBlog) => {
  const {post, comments} = postRecord;

  return (
    <tr>
      <td className="text-black">
        <Link className="text-blue-500 hover:text-blue-700" to={`/app/main/blog/preview-post/${post.postKey}`}>{post.postTitle}</Link>
      </td>
      <td className="text-black">{moment(post.postDate).format('Do MMMM gggg')}</td>
      <td className="text-black">{post.tags.length}</td>
      <td className="text-black">
        <BlogPostCommentsPost nbrCmnts={comments.length} postKey={post.postKey} />
      </td>
      <td className="text-black">
        <PublishStatusComp 
          postKey={post.postKey}
          published={post.published}
          afterPublish={afterPublish} /></td>
    </tr>
  )
}
const PublishStatusComp = ({published, postKey, afterPublish}: IPublishStatusComp) => {
  const onPublishPost = async () => {
    const bAsk = await appFxs.showConfirm(languages.publish, languages.publishPostMsg);
    if(!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost('alumni/publissinglepostbykey', {
      postKey
    },true);

    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }   
    const lst =  oOper.lstOfBlogRecords!;
    afterPublish(lst);
  }

  return (
    <div>
      {published && <span>{languages.published}</span>}
      {!published && 
        <button className="btn btn-sm bg-slate-400" onClick={onPublishPost}>
          {languages.publish}
        </button>
      }
    </div>
  );
}
const BlogPostCommentsPost = ({postKey, nbrCmnts}:IBlogPostCommentsPost) => {
  return (
    <>
    {nbrCmnts === 0 && <span>{nbrCmnts}</span>}
    {nbrCmnts > 0 && 
      <Link className="text-blue-400 hover:text-blue-500"  
        to={`/app/main/blog/preview-comments/${postKey}`}>
        {nbrCmnts} {languages.comments.toLowerCase()}
      </Link>
    }
    </>    
  );
}
