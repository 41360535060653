/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import styles from './SimpleInputDate.module.css';
import { useEffect, useState } from 'react';

type SimpleInputDateProps = {
  name: string;
  id: string;
  classes: string;
  value: Date;
  afterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
};

const SimpleInputDate = ({
  id,
  name,
  classes,
  value,
  afterChange,
  disabled,
}: SimpleInputDateProps) => {    
    const [dValue, setDValue] = useState<Date>(value);
    const [vFormat, setVFormat] = useState(moment(dValue).format("DD-MM-YYYY HH:mm"));


    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const fDate = new Date(e.target.value);

      setDValue(fDate);
      setVFormat(moment(fDate).format("DD-MM-YYYY HH:mm"));
      afterChange(e);
    };

    useEffect(() => {
      setVFormat(moment(dValue).format("DD-MM-YYYY HH:mm"));
    }, [dValue]);

  return (
    <input type="datetime-local"
        data-date={vFormat}
        className={`${classes} ${styles.inputDateTime}`} 
        onChange={onChange} 
        id={id} 
        name={name}
        value={moment(value).format('YYYY-MM-DD HH:mm')}
        disabled={disabled} />
  );
};

export default SimpleInputDate;
