
import WiseModal from "../modalportal/WiseModal";

import getLang from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { useState } from "react";
import { ICalendarEvent } from "src/models/smarttypes";
import SimpleInputDate from "../inputcomponents/SimpleInputDate";


const languages = getLang();
const appFxs = generalFxs();

interface ICalendarEventError {
  title?: string;
  start?: string;
  end?: string;
}
type CalendarEventFormProps = {
  isOpen: boolean;
  dialogWidth: number;
  defaultValue: ICalendarEvent;
  isUserOwner: boolean;
  eventRecordStatus: number,
  onClose: (fData?: ICalendarEvent) => void;
  onDelete: (fData: ICalendarEvent) => void;
  onSaveRecord: (fData: ICalendarEvent) => void;
};

const CalendarEventForm = ({
    isOpen, 
    dialogWidth, 
    onClose, 
    defaultValue,
    isUserOwner,
    eventRecordStatus,
    onDelete,
    onSaveRecord,
}:CalendarEventFormProps) => {


    if(!isOpen) return null;
    return (
        <WiseModal 
            isOpen={isOpen} 
            onClose={() => onClose()} 
            titleBox={languages.calendarEvent} 
            width={dialogWidth ?? 450}
            hasFooter={false}>
            <CalendarFormWrapper 
                formEltData={defaultValue}
                isUserOwner={isUserOwner}
                eventRecordStatus={eventRecordStatus}
                onCloseDialog={onClose}
                onDelete={onDelete}
                onSaveRecord={onSaveRecord}/>
        </WiseModal>
    )
}
type CalendarFormWrapperProps = {
    formEltData: ICalendarEvent;
    isUserOwner: boolean;
    onCloseDialog: (fData?: ICalendarEvent) => void;
    eventRecordStatus: number;
    onDelete: (fData: ICalendarEvent) => void;
    onSaveRecord: (fData: ICalendarEvent) => void;
}
const CalendarFormWrapper = ({
    formEltData, 
    isUserOwner, 
    onCloseDialog,
    eventRecordStatus,
    onDelete,
    onSaveRecord,
}: CalendarFormWrapperProps) => {
    const [formData, setFormData] = useState<ICalendarEvent>({...formEltData});
    const [formError, setFormError] = useState<ICalendarEventError>({});

    const onChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const dDate = new Date(value);

      setFormData({ ...formData, [name]: dDate });
    };
    const onCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      const vChecked = e.target.checked;
      const endDate = vChecked ? formData.end: formData.start;
      setFormData({...formData, hasEnd:vChecked, end: endDate });
    }
    const deleteEventRecord = async () => {
        const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteCalEvtMsg);
        if(!bAsk.isConfirmed) return;
        onDelete(formData)
    }
    const saveRecordToDB = () => {
        const errors: ICalendarEventError = {};
        if(!formData.title || formData.title.length ===0) {
        errors.title = languages.fldRequired;
        }

        const startDate = formData.start.getTime();
        const endDate = formData.end.getTime();

        if(endDate < startDate && formData.hasEnd){
            errors.end = languages.dateEndError;
        }


        setFormError(errors);

        if(Object.keys(errors).length>0)return;

        onSaveRecord(formData);
    }

    return (
        <div className="flex flex-col">        
            <div className="py-4 flex flex-col max-h-[400px] overflow-y-auto px-2">
                <label className="form-control w-full">
                <div className="label">
                    <span className="label-text">{languages.eventTitle}</span>
                </div>
                <input
                    type="text"
                    placeholder={languages.eventTitle.toLowerCase()}
                    className="input input-bordered w-full ring-1"
                    value={formData.title}
                    name="title"
                    onChange={onChangeFormData}
                    disabled={eventRecordStatus!==0}
                />
                {formError.title && (
                    <div className="label">
                    <span className="label-text-alt text-red-500">
                        {formError.title}
                    </span>
                    </div>
                )}
                </label>

                {/* bgColor */}
                <label className="form-control w-full">
                <div className="label">
                    <span className="label-text">{languages.eventColor}</span>
                </div>
                <select
                    className="ring-1 select select-bordered w-full"
                    value={formData.backgroundColor}
                    name="backgroundColor"
                    disabled={eventRecordStatus!==0}
                    onChange={(e) =>
                    setFormData({ ...formData, backgroundColor: e.target.value })
                    }
                >
                    <option value="#fff">{languages.default}</option>
                    <option value="#4CAF50">Green</option>
                    <option value="#cddc39">Lime</option>
                    <option value="#ff5722">Deep orange</option>
                    <option value="#607d8b">Blue gray</option>
                </select>
                </label>

                {/* start */}
                <label className="form-control w-full">
                <div className="label">
                    <span className="label-text">{languages.start}</span>
                </div>
                <SimpleInputDate
                    afterChange={onDateChanged}
                    classes="input input-bordered w-full ring-1"
                    id="start"
                    name="start"
                    value={formData.start}
                    disabled={eventRecordStatus!==0}
                />
                </label>

                {/* has end */}
                <div className="flex items-center gap-2 w-full py-3">
                <input
                    type="checkbox"
                    className="toggle border border-solid"
                    id="hasEnd"
                    checked={formData.hasEnd}
                    onChange={onCheckedChanged}
                    disabled={eventRecordStatus!==0}
                />
                <label htmlFor="hasEnd" className="text-black cursor-pointer">
                    {languages.hasEndDate}
                </label>
                </div>

                {/* end */}
                <label className={`form-control w-full ${!formData.hasEnd && "hidden"}`}>
                <div className="label">
                    <span className="label-text">{languages.end}</span>
                </div>
                <SimpleInputDate
                    afterChange={onDateChanged}
                    classes="input input-bordered w-full ring-1"
                    id="end"
                    name="end"
                    value={formData.end}
                    disabled={eventRecordStatus!==0}
                />
                {formError.end && (
                    <div className="label">
                        <span className="label-text-alt text-red-500">
                            {formError.end}
                        </span>
                    </div>
                )}
                </label>

                
            </div>
            <div className="modal-action">
            <div className="w-full flex justify-between">
                {isUserOwner ? (
                <div>
                    <button type="button" className="btn btn-warning" onClick={deleteEventRecord}>
                        {languages.delete}
                    </button>
                </div>
                ) : (
                <span></span>
                )}
                <div className="flex gap-2 items-center">
                    <button className="btn" onClick={() => onCloseDialog()}>
                        {languages.close}
                    </button>
                {(eventRecordStatus === 0 || isUserOwner) && (
                    <button type="button" className="btn btn-primary" onClick={saveRecordToDB}>
                    {languages.save}
                    </button>
                )}
                </div>
            </div>
            </div>
        </div>
    );
}






export default CalendarEventForm;