/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { IAlumniShortUser, ITagRec } from "src/models/smarttypes";

import alumniImg from 'src/assets/images/alumniimg.png';

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import appDecl from 'src/utils/declarations';

import NetServices from "src/utils/netservices";
import { ImWarning } from "react-icons/im";
import WiseModal from "../modalportal/WiseModal";


const languages = getLanguages();
const appFxs = getFxs();


interface IRecipientFriendField {
    recipients: ITagRec[],
    afterRecipientsUpdate: (updates: IAlumniShortUser[]) => void,
}


const RecipientFriendField = ({ recipients, afterRecipientsUpdate }: IRecipientFriendField) => {
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [friends, setFriends] = useState<IAlumniShortUser[]>([]);
    const [fitered, setFiltered] = useState<IAlumniShortUser[]>([]);
    const [selected, setSelected] = useState<IAlumniShortUser[]>([]);
    const [contactVis, setContactVis] = useState<boolean>(false);

    const inputTag = useRef<HTMLInputElement>(null);

    const [newTag, setNewTag] = useState("");

    const inputId = appFxs.generateUUID();

    const populateMyShortFriends = async () => {
      setServerError(false);
      setLoading(true);
      const oper = await NetServices.requestGet("alumni/getmyfriends", false);
      if (!oper.bReturn) {
        setServerError(true);
        setLoading(false);
        return;
      }

      setFriends(oper.allShortFriends!);
      setLoading(false);
    };

    const afterSelected = (e: IAlumniShortUser) => {
        if(selected.includes(e))return;
        const f = [...selected]
        f.push(e);
        setSelected(f);
        setNewTag('');
        inputTag.current?.focus();
    }
    const onRemove = (em: string) => {
      const f = selected.filter(o => o.userEmail !== em);
      setSelected(f);
    }
    const onRemoveKeyBack = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isBackSpace = (e.keyCode === 8)
        if(!isBackSpace) return;
        if(inputTag.current?.value) return;
        if(selected.length === 0)return;
        const finalSel = [...selected];
        finalSel.pop();        
        setSelected(finalSel);
    }
    const onContactDisplClose = (e?: IAlumniShortUser) => {
      setContactVis(false);
      if(!e)return;
      if(selected.includes(e))return;
        const f = [...selected]
        f.push(e);
        setSelected(f);
        setNewTag('');
        inputTag.current?.focus();
    }


  useEffect(() => {
    populateMyShortFriends();
  }, []);

  useEffect(() => {
    if(!newTag){
        setFiltered([]);
    }else{
        const f = friends.filter(o => 
            (o.userEmail.toLowerCase().includes(newTag.toLowerCase()) || 
            o.userName.toLowerCase().includes(newTag.toLowerCase())) && 
            selected.filter(t => t.userEmail === o.userEmail).length === 0);
        
        setFiltered(f);
    }
    
  }, [recipients, friends, newTag]);

  useEffect(() => {
    afterRecipientsUpdate(selected);
  }, [selected]);

  if (loading) {
    return (
      <div className="flex items-center gap-2">
        <span className="loading loading-infinity loading-lg"></span>
        <span>{languages.loadingRecipients.toLowerCase()}</span>
      </div>
    );
  }
  if (!loading && serverError) {
    return (
      <div className="flex items-center gap-2 h-full">
        <ImWarning />
        <span>{languages.serverError.toLowerCase()}</span>
        <button className="flex items-center justify-center border border-solid border-slate-300 rounded-full p-2 bg-slate-200 hover:bg-slate-300 hover:border-slate-400"
            onClick={() => populateMyShortFriends()}>
            <i className="fa fa-refresh"></i>
        </button>
      </div>
    );
  }
  return (
    <>
      <ContactsDisplayerComp 
        visible={contactVis}
        onClose={onContactDisplClose}
        contacts={friends} />
      <div className="flex flex-wrap items-center h-full">
        <div className="flex-1  flex flex-wrap gap-1">
          {selected.length > 0 && (
            <div className="pl-3 flex items-center gap-1 flex-wrap">
              {selected.map((wo, idx) => (
                <FriendElementSelected
                  key={idx}
                  short={wo}
                  onRemove={onRemove}
                />
              ))}
            </div>
          )}
          <div className="flex-1 ml-2 flex items-center relative">
            <button className="b-button mr-2 p-1 rounded-full border border-solid border-slate-300 w-6 h-6 flex items-center justify-center cursor-pointer"
              onClick={() => setContactVis(true)}>
              <i className="fa fa-plus"></i>
            </button>
            <input
              type="text"
              name="inputTag"
              ref={inputTag}
              id={inputId}
              className="flex-1"
              placeholder={languages.tagHolderText.toLowerCase()}
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={onRemoveKeyBack}
            />
            <div
            className={`absolute top-9 bottom-0 left-7 bg-white z-10 ${
              fitered.length === 0 && "hidden"
            }`}
          >
            <div className="h-auto bg-white px-3 py-2 border border-solid border-slate-300 rounded-md divider-y">
              {fitered.map((o, idx) => (
                <FriendElementComp
                  short={o}
                  afterSelected={afterSelected}
                  key={idx}
                />
              ))}
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default RecipientFriendField;

const FriendElementComp = ({short, afterSelected}: {short: IAlumniShortUser, afterSelected: (e: IAlumniShortUser) => void,}) => {
    const imgSrc = !short.imagePath ? alumniImg: 
    `${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${short.imagePath}`;

    return (      
      <div className="group flex items-center gap-2 cursor-pointer hover:bg-slate-400 px-2 py-1 rounded-lg w-fit transition-all"
      onClick={() => afterSelected(short)}>
        <div className="avatar">
          <div className="w-6 rounded-full">
            <img src={imgSrc} alt="" />
          </div>
        </div>
        <span className="text-sm group-hover:font-semibold">{short.userName}</span>
      </div>
    );
}

const FriendElementSelected = ({short, onRemove}: {short: IAlumniShortUser, onRemove: (em: string) => void}) => {
    const imgSrc = !short.imagePath ? alumniImg: 
    `${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${short.imagePath}`;

    return (
      <>
        

        <div className="group flex items-center gap-2 cursor-pointer hover:bg-slate-400 px-2 py-1 rounded-3xl border border-solid border-slate-300">
          <div className="avatar">
            <div className="w-6 rounded-full">
              <img src={imgSrc} alt="" />
            </div>
          </div>
          <span className="text-sm group-hover:font-semibold">
            {short.userName}
          </span>
          <button
            className="hover:text-white"
            title={languages.remove}
            onClick={() => onRemove(short.userEmail)}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </>
    );
}

interface IContactsDisplayerComp {
  visible: boolean,
  contacts: IAlumniShortUser[],
  onClose: (o?: IAlumniShortUser) => void,
}
const ContactsDisplayerComp = ({contacts, visible, onClose}:IContactsDisplayerComp) => {
  const onSelect = (e: IAlumniShortUser) => {
    onClose(e);
  }

  if(!visible)return null;
  return(
    <WiseModal
      isOpen={visible}
      onClose={()=>{
        onClose();
      }}
      hasFooter={false}>
        <ListOfContactsComp 
          contatcs={contacts}
          onSelect={onSelect} />      
    </WiseModal>
  );
}

interface IListOfContactsComp {
  contatcs: IAlumniShortUser[], 
  onSelect: (e: IAlumniShortUser) => void,
}
const ListOfContactsComp = ({contatcs, onSelect}: IListOfContactsComp) => {
  return(
    <ul className="flex flex-wrap items-center max-h-[180px] overflow-y-auto gap-1">
      {contatcs.map((c, idx) => 
        <ContactWrapperForShort 
          contact={c} 
          key={idx} 
          onSelect={onSelect}/>)}
    </ul>
  );
}
interface IContactWrapperForShort {
  contact: IAlumniShortUser,
  onSelect: (e: IAlumniShortUser) => void,
}
const ContactWrapperForShort = ({contact, onSelect}:IContactWrapperForShort) => {
  const imgSrc = !contact.imagePath ? alumniImg: 
    `${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${contact.imagePath}`;

  return (
    <li className="flex items-center py-1 px-2 gap-2 rounded-2xl border border-slate-300 border-solid cursor-pointer hover:bg-slate-200"
    onClick={() => onSelect(contact)}>
      <div className="avatar">
          <div className="w-6 rounded-full">
            <img src={imgSrc} alt="" />
          </div>
        </div>
        <span className="text-sm group-hover:font-semibold">{contact.userName}</span>
    </li>
  );
}