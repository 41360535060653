
type CardProps = {
    children: React.ReactNode;
    classes? : string;
    childClasses? : string;
    onClick?: () => void;
}

const Card = ({children, classes, childClasses}: CardProps) => {
    return (
        <section className={`text-black flex flex-col bg-white border shadow-sm rounded-md ${classes ?? ''}`}>
            <div className={`p-4 md:p-5 ${childClasses ?? ''}`}>
                {children}
            </div>
        </section>
    );
};

export default Card;

export const CardElt = ({children, classes, onClick}: CardProps) => {
    const finalClick = () => {
        if(!onClick) return;
        onClick();
    }
    return (
        <section className={`flex flex-col bg-white border rounded-md ${classes ?? ''}`} onClick={finalClick}>
            {children}
        </section>
    );
}
