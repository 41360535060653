/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

import { IInputData } from "src/models/smarttypes"
import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";


const languages = getLanguages();
const appFxs = generalFxs();

export const FormInputElt = ({
    name, 
    type, 
    placeholder, 
    maxLength, 
    selectOptions, 
    rows,
    onChange,
    displayClass,
    layoutStyle,
    value,
    label,
    errorMessage,
    otherClasses,
    disabled,

}: IInputData) => {

    const oType = type ?? 'text'
    const dClass = displayClass ?? 'block';
    const dLayout = layoutStyle ?? 'column';

    const oOptions = selectOptions ?? [{id: '', label: 'select value'}];
    const oRows = rows ?? 3;
    const isInput = oType !== "textarea" && oType !== "select" && oType !== "date";
    const isDate = oType === "date";
    const isTextArea = oType === "textarea";

    const oPlaceholder = placeholder || languages.chooseOption;
    const [fieldValue, setFieldValue] = useState(value)



    const changeElt = (fvalue: any) => {
        setFieldValue(fvalue);
        if(!onChange)return;
        onChange!({field: name, value: fvalue})
    }
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const bNumeric = oType === 'tel';
        if(bNumeric){
            appFxs.strictlyNbr(e)
        }
    }

  return (
    <div className={`wise-form-elt-input ${otherClasses ?? ''} ${errorMessage && 'has-error'}`}  style={{ display: dClass }}>
      <div className="flex flex-col">
        <div
          className={`w-field-zone flex ${
            dLayout === "row"
              ? `flex-row is-display-row ${isTextArea ? 'items-start': 'items-center'}`
              : "flex-col is-display-col"
          }`}
        >
          <label className={`inline-block ${dLayout === "row" && 'grow-0 '}`} htmlFor={name}>{label ?? ""}</label>
          <div className={`${dLayout === "row" && 'flex-1 ml-6'}`} >
            <span
              className={`form-ctrl-wrapper ${
                oType === "select" && "select-wrapper"
              }`}
            >
              {isInput && (
                <input
                  type={oType}
                  placeholder={placeholder}
                  maxLength={maxLength ?? 50}
                  name={name}
                  id={name}
                  onKeyPress={handleKeyPress}
                  value={fieldValue ? fieldValue!.toString() : ''}
                  onChange={(e) => changeElt(e.target.value)}
                  disabled={disabled ?? false}
                />
              )}
              {isDate && <SimpleInputDate name={name} value={fieldValue} />}
              {oType === "select" && (
                <>
                  <select
                    name={name}
                    id={name}
                    onChange={(e) => changeElt(e.target.value)}
                    value={fieldValue ? fieldValue.toString(): ''}
                    disabled={disabled ?? false}
                  >
                    {oPlaceholder && <option value="">{oPlaceholder}</option>}
                    {oOptions.map((oOp) => (
                      <option key={oOp.id} value={oOp.id}>
                        {oOp.label}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {oType === "textarea" && (
                <>
                  <textarea
                    name={name}
                    rows={oRows}
                    placeholder={placeholder}
                    style={{ resize: "none" }}
                    value={fieldValue ? fieldValue.toString(): ''}
                    onChange={(e) => changeElt(e.target.value)}
                    disabled={disabled ?? false}
                  ></textarea>
                </>
              )}
            </span>
          </div>
        </div>
        <div className="error-area pb-4">
          <span className={`inline-block ${errorMessage ?? 'px-0'}`}>{errorMessage ?? ""}</span>
        </div>
      </div>
    </div>
  );
}

export const FormInputNumber = (
    {displayClass, value, name, min, step, max, onChange}: IInputData) => {

    const oStep = step ?? 1;
    const oMin = min ?? 1;
    const oMax = max ?? 10;

    const dClass = displayClass ?? 'inline-block';

    const [fValue, setFValue] = useState<number>(Number(value));
    
    const onTapArrow = (type: 'up'|'down') => {
        if(type === 'down'){
            const f = fValue - oStep;
            if(fValue === oMin) return;

            if(f < oMin) {
                setFValue(oMin);
                if(!onChange) return;
                onChange!({field: name, value: oMin});
            }else{
                setFValue(f);
                if(!onChange) return;
                onChange!({field: name, value: f});
            }
            return;
        }
        if(type === 'up'){
            const f = fValue + oStep;
            if(fValue === oMax) return;
            if(f > oMax) {
                setFValue(oMax);
                if(!onChange) return;
                onChange!({field: name, value: oMax});
            }else{
                setFValue(f);
                if(!onChange) return;
                onChange!({field: name, value: f});
            }

            return;
        }
    }

    return (
        <div className="form-input-nbr" style={{display: dClass}}>
            <input type="text" 
                step={oStep} 
                min={oMin} 
                max={oMax}
                name={name}
                id={name}
                value={fValue}
                onChange={() => {}}
                disabled={true}/>
            <a className="arrow arrow-plus" onClick={() => onTapArrow("up")}>
                <i className="fa fa-caret-up"></i>
            </a>
            <a className="arrow arrow-down" onClick={() => onTapArrow("down")}>
                <i className="fa fa-caret-down"></i>
            </a>
        </div>
    );
}


export const FormSpecGroupRadio = ({name, value, groupRadioValues, onChange}: IInputData) => {
    const fValues = groupRadioValues || [];
    const [oValue, setOValue] = useState(value);

    const onFieldCange = (val: string) => {
        setOValue(val);
        if(onChange === null)return;
        onChange!({field: name, value: oValue});
    }


    if(fValues.length ===0){
        return (
            <div className="h-[50px] text-white flex items-center px-3 border border-solid border-slate-500 bg-slate-400 rounded-[10px]">
                <span>{languages.noOptionsFound}</span>
            </div>
        );
    }
    return(
        <ul className="special-grp-radio">
            {fValues.map((oOpt, idx) => {
                const _id = appFxs.generateUUID() + idx.toString();
                return (
                  <li key={_id}>
                    <input id={_id} 
                        type="radio" 
                        value={oOpt.value} 
                        name={name} 
                        onChange={(_) => {
                            onFieldCange(oOpt.value);
                        }}
                        checked={oValue === oOpt.value}/>
                    <label htmlFor={_id}>{oOpt.label}</label>
                    <div>
                      <p>{oOpt.detail}</p>
                    </div>
                  </li>
                );
            })}            
        </ul>
    );
}

const SimpleInputDate = ({value, name, onChange}: IInputData) => {
    const [date, setDate] = useState<Date>(value ? new Date(value!): new Date());

    const onSetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDate(new Date(e.target.value));
        if(!onchange)return;
        onChange!({field: name, value: e.target.value});
    }

    return <input type="date"
        name={name}
        id={name}
        value={date.toLocaleDateString('en-CA')}
        onChange={onSetDate}
    />
}