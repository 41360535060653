/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { ReactComponent as CancelSvg } from "src/assets/images/svg/cancel_icon.svg";

import { ReactComponent as FlagFr } from "src/assets/images/svg/flag-fr.svg";
import { ReactComponent as FlagEn } from "src/assets/images/svg/flag-gb.svg";

import { WithContext as ReactTags } from 'react-tag-input';

import Card from "src/components/Card";

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { ImCloudCheck, ImFilePicture} from "react-icons/im";
import SearchWebUmagesV2 from "src/components/searchwebimagesv2/SearchWebUmagesV2";
import { IAlumniBlogPost, IPexelsImage, ITagRec } from "src/models/smarttypes";
//import WiseHtmlEditor from "src/components/wisehtmleditor/WiseHtmlEditor";
import LoadingWaitSpace from "src/components/LoadingWaitSpace";
import NetServices from "src/utils/netservices";
import WiseDropDown from "src/components/wsedropdown/WiseDropDown";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";

import * as jquery from 'jquery';
import { RealTinyMceEditor } from "src/components/tinyeditor/TinyMceEditor";

const languages = getLanguages();
const appFxs = getFxs();

interface IFeatureImgFld {
  imageUrl?: string;
  imageFile?: File;
  type: "unknown" | "url" | "file";
  set: boolean;
}
interface IFormData {
  postSubject: string;
  postTitle: string;
  postContent: string;
  tags: string[];
  postLang: string;
}
const defaultData: IFormData = {
  postSubject: "",
  postTitle: "",
  postContent: "",
  tags: [],
  postLang: 'en',
};
const defaultImage: IFeatureImgFld = {
  set: false,
  type: "unknown",
  imageFile: undefined,
  imageUrl: undefined,
};
const lstLanguages = [
  {id: 'en', label: languages.english_m},
  {id: 'fr', label: languages.french_m},
];



const NewBlogPostPage = () => {
  document.title = `${languages.newBlog} | Alumni - ${languages.schoolNameOnly}`;

  const [formData, setFormData] = useState<IFormData>(defaultData);
  const [feauturedImg, setFeaturedImg] = useState<IFeatureImgFld>(defaultImage);
  const [pexelImageData, setPexelImageData] = useState<IPexelsImage | null>(null);
  const [fileImageUrlBg, setFileImageUrlBg] = useState<string>('');

  const [selectedLang, setSelectedLang] = useState<string>(languages.english_m);

  const [isPexelsOpen, setIsPexeslOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isSearchImgLinks, setIsSearchImgLinks] = useState<boolean>(false);

  const [blogTags, setBlogTags] = useState<ITagRec[]>([])

  const fileElt = useRef<HTMLInputElement | null>(null);

  const {postEltKey} = useParams();
  const navigate = useNavigate();

  const isReadyToSubmit = () => {
    const spanText = jquery('<span>').html(formData.postContent).text();

    if(!formData.postSubject){
      appFxs.showAlert(languages.warning, languages.warningPostSubject)
      return false;
    }
    if(!formData.postTitle){
      appFxs.showAlert(languages.warning, languages.warningPostTitle)
      return false;
    }
    if(formData.tags.length === 0){
      appFxs.showAlert(languages.warning, languages.warningPostTags)
      return false;
    }
    if(!feauturedImg.set){
      appFxs.showAlert(languages.warning, languages.warningPostFimg)
      return false;
    }
    if(!spanText || spanText.length < 150){      
      appFxs.showAlert(languages.warning, languages.warningPostFimg)
      return false;      
    }

    

    return true;
  }

  const saveBlog = () => {
    const bIsReady = isReadyToSubmit();

    if(!bIsReady) return;

    const recKey =  appFxs.generateUUID();
    const oRec:IAlumniBlogPost = {
      postKey: recKey,
      publisherEmail: '',
      postDate: new Date(),
      postSubject: formData.postSubject,
      postTitle: formData.postTitle,
      postContent: formData.postContent,
      featuredImage: feauturedImg.type === "url" ? feauturedImg.imageUrl!: '',
      tags: formData.tags,
      published: false,
      featuredImageType: feauturedImg.type === "file" ? 'file': 'url',
      postLang: formData.postLang
    }

    if(feauturedImg.type === "file"){
      saveRecordWithFile(oRec);
    }else{
      saveRecordWithUrl(oRec);
    }
  };
  const saveRecordWithUrl = async (record: IAlumniBlogPost) => {
    const finalData = appFxs.getFormData(record);

    const retData = await NetServices.requestPostWithFile(
      `alumni/saveblogpostwithurl`,
      finalData,
      true,
    );
    if (!retData.bReturn) {
      appFxs.showAlert(retData.msgTitle, retData.msgBody);
      return;
    }

    navigate('/app/main/blog');
  }
  const saveRecordWithFile = async (record: IAlumniBlogPost) => {
    const finalData = appFxs.getFormData(record);
    finalData.append("alumniBlogFile", feauturedImg.imageFile!);

    const retData = await NetServices.requestPostWithFile(
      `alumni/saveblogpostwithfile`,
      finalData,
      true,
    );

    if (!retData.bReturn) {
      appFxs.showAlert(retData.msgTitle, retData.msgBody);
      return;
    }

    navigate('/app/main/blog');
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  
  const onRemoveSelectedImg = () => {
    setFeaturedImg({...feauturedImg, imageFile: undefined, imageUrl: undefined, set: false, type:'unknown'});
    setPexelImageData(null);
  }
  const onSelectImage = () => {};

  const onSelectImagePexels = () => {
    setIsPexeslOpen(true);
  }
  const afterPexelsClose = (oImg: IPexelsImage | null) => {
    setIsPexeslOpen(false);
    if(!oImg) return;
    setPexelImageData(oImg);
    setFeaturedImg({...feauturedImg, type:"url", imageUrl: oImg!.src.landscape, imageFile: undefined, set: true, })
  }

  
  const afterSearchLinks = (oImg: IPexelsImage | null) => {
    setIsSearchImgLinks(false);
    if(!oImg) return;
    navigator.clipboard.writeText(oImg!.src.tiny!);
    appFxs.showAlert(languages.photoImage, languages.photoImageLinkCp)
  }

  const onSelectFileComp = () => {
    fileElt.current?.click();
  }
  const afterImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstFile = e.target.files![0];

    setFeaturedImg({...feauturedImg, type:"file", imageUrl: undefined, imageFile: firstFile, set: true, });
    setImageFileBgUrl(firstFile);
  }
  const setImageFileBgUrl = (oFile: File) => {
    const reader = new FileReader();
    reader.addEventListener("load", function () {
      const imageURL = reader.result!;
      const fBg = `url(${imageURL})`;
      setFileImageUrlBg(fBg);
    }, false);
    if (oFile) {
      reader.readAsDataURL(oFile);
    }
  }
  const onPostContentchanged = (e: string) => {
    setFormData({...formData, postContent: e});
  }

  const getSelectedData = () => {

  }

  //#region Tags
  const handleTagDrag = (tag:ITagRec, currPos:number, newPos:number) => {
    const newTags = blogTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setBlogTags([...newTags]);
  };
  const handleTagAddition = (tag:ITagRec) => {
    const recExist = blogTags.find(o => o.text === tag.text);
    if(recExist) return;

    const newTags = [...blogTags, tag];
    setBlogTags([...newTags]);
  };
  const handleTagDelete = (i: number) => {
    const newTags = blogTags.filter((_, index) => index !== i);
    setBlogTags([...newTags]);
  };
  //#endregion


  useEffect(() => {
    if(postEltKey === 'newblogpost'){
      setIsLoading(false);
    }else{
      getSelectedData();
    }
  }, []);

  useEffect(() => {
    const finalTags = blogTags.map(o => o.text);
    setFormData({...formData, tags: finalTags});
  }, [blogTags])



  if(isLoading) return <LoadingWaitSpace />
  return (
    <div className="flex flex-col gap-3">
      <SearchWebUmagesV2
        isOpen={isPexelsOpen}
        titleBox={languages.searchFromPexels}
        onClose={afterPexelsClose}
        dialogWidth={650}
      />

      <SearchWebUmagesV2
        isOpen={isSearchImgLinks}
        titleBox={languages.copyOnlineImagesLink}
        onClose={afterSearchLinks}
        dialogWidth={650}
      />

      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.newBlog}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={saveBlog}
                >
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.newBlogMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* Body */}
      <Card>
        <div className="flex flex-col gap-2">
          <div className="flex flex-wrap mb-4">
            <div className="basis-full md:basis-1/2 mb-2">
              <label className="form-control block pr-4">
                <div className="label">
                  <span className="label-text">{languages.subject}</span>
                </div>
                <input
                  type="text"
                  placeholder={languages.blogSubjectEx}
                  className="input input-bordered w-full ring-1"
                  name="postSubject"
                  onChange={onChange}
                />
              </label>
            </div>
            <div className="basis-full md:basis-1/2 mb-2">
              <label className="form-control block pr-4">
                <div className="label">
                  <span className="label-text">{languages.title}</span>
                </div>
                <input
                  type="text"
                  placeholder={languages.blogTitleEx}
                  className="input input-bordered w-full ring-1"
                  name="postTitle"
                  onChange={onChange}
                />
              </label>
            </div>
          </div>
          <div className="w-full mb-2 flex flex-col gap-1">
              <label className="form-control block pr-4 text-black">
                {languages.tags}
              </label>

              <div className="w-full input input-bordered ring-1 pt-1">
                <ReactTags
                  tags={blogTags}
                  handleDelete={handleTagDelete}
                  handleAddition={handleTagAddition}
                  handleDrag={handleTagDrag}
                  inputFieldPosition="inline"
                  placeholder={languages.tagHolderText}
                />
              </div>
              
            </div>
          <div className="flex flex-col mb-5">
            <div className="flex items-center justify-between mb-3">
              <h4 className="font-semibold mb-4 text-black">
                {languages.featuredImage}
              </h4>
              <div>
                <WiseDropDown 
                  position="right" 
                  hasDropdown={false} 
                  options={lstLanguages}
                  onSelected={(e) => {
                    setFormData({...formData, postLang: e.id});
                    setSelectedLang(e.id === 'en'? languages.english_m: languages.french_m)
                  }}>
                    <div className="flex items-center gap-2">
                        {formData.postLang === 'en' && <FlagEn width={20} height={20} />}
                        {formData.postLang === 'fr' && <FlagFr width={20} height={20} />}
                        <span className="text-blue-600">
                          {selectedLang}
                        </span>
                    </div>
                </WiseDropDown>
              </div>
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="featured-image-area basis-full md:basis-[65%] mx-auto">
                <div className="flex items-center justify-center">
                  {feauturedImg.type === "unknown" && (
                    <div
                      className="w-full aspect-video bg-slate-400 cursor-pointer rounded-lg flex items-center justify-center"
                      onClick={onSelectImage}
                    >
                      <div className="flex gap-2">
                        <button
                          className="p-3 rounded-full border border-solid text-white hover:border-orange-800 hover:text-orange-800"
                          title={languages.selectFileFromComp}
                          onClick={onSelectFileComp}
                        >
                          <ImFilePicture />
                        </button>

                        <button
                          className="p-3 rounded-full border border-solid text-white hover:border-orange-800 hover:text-orange-800"
                          title={languages.selectFileFromPexels}
                          onClick={onSelectImagePexels}
                        >
                          <ImCloudCheck />
                        </button>
                        <input
                          accept="image/*"
                          type="file"
                          className="hidden"
                          ref={fileElt}
                          onChange={afterImageSelected}
                        />
                      </div>
                    </div>
                  )}
                  {feauturedImg.type === "file" && (
                    <div
                      className="w-full aspect-video bg-slate-400 relative rounded-lg overflow-hidden"
                      style={{
                        backgroundImage: fileImageUrlBg,
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="absolute bottom-0 left-0 right-0 h-full z-0 overlay-from-bot-top"></div>
                      <button
                        className="absolute top-3 right-3 p-2 flex items-center justify-center"
                        title={languages.remove}
                        onClick={onRemoveSelectedImg}
                      >
                        <CancelSvg width={20} height={20} fill="#000" />
                      </button>
                    </div>
                  )}
                  {feauturedImg.type === "url" && (
                    <div
                      className="w-full aspect-video bg-slate-400 relative rounded-lg overflow-hidden"
                      style={{
                        backgroundImage: `url(${feauturedImg.imageUrl})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="absolute bottom-0 left-0 right-0 h-full z-0 overlay-from-bot-top"></div>
                      <button
                        className="absolute top-3 right-3 p-2 flex items-center justify-center"
                        title={languages.remove}
                        onClick={onRemoveSelectedImg}
                      >
                        <CancelSvg width={20} height={20} fill="#000" />
                      </button>
                      <div className="absolute bottom-0 left-0 right-0 h-fit p-2">
                        <strong className="text-zinc-400 text-sm">
                          {languages.photographer}
                        </strong>
                        :&nbsp;
                        <a
                          href={pexelImageData!.photographer_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="inline-block text-white">
                            {pexelImageData!.photographer}
                          </span>
                        </a>
                        <br />
                        <span className="inline-block text-white text-[12px]">
                          {pexelImageData!.alt}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="tags-area flex flex-col basis-full md:basis-[31%] border border-solid rounded-lg overflow-hidden">
                <div className="tags-header w-full flex items-center justify-between bg-slate-100 hover:bg-slate-300 p-3 transition-all">
                  <h6 className="text-black">{languages.tags}</h6>
                  <button
                    className={`comp-btn ${
                      tagAreaOpen ? "hidden" : "inline-flex"
                    }`}
                    onClick={() => setTagAreaOpen(true)}
                  >
                    <PlusSvg className="plus-btn" />
                  </button>
                </div>
                <div className="tags-body">
                  {tagAreaOpen && (
                    <div className="p-3 mb-3 flex items-center justify-between gap-2">
                      <input
                        type="text"
                        placeholder="Type here"
                        className="input flex-1 border border-solid border-slate-300 p-2 focus:ring-0"
                        name="tags"
                        value={selectedTagValue}
                        onChange={(e) => setSelectedTagValue(e.target.value)}
                      />
                      <div className="flex gap-1">
                        {selectedTagValue && (
                          <button
                            className=""
                            onClick={() => onSetTagArea("clear")}
                          >
                            <CancelSvg
                              width={16}
                              height={16}
                              className="special-ink-svg"
                            />
                          </button>
                        )}
                        <button onClick={() => onSetTagArea("enter")}>
                          <CheckSvg
                            width={16}
                            height={16}
                            className="special-ink-svg"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                  <ul className="p-3">
                    {formData.tags.length === 0 && (
                      <div className="text-black">{languages.noTagsYet}</div>
                    )}
                    {formData.tags.map((oTag, idx) => (
                      <li className="flex justify-between mb-2" key={idx}>
                        <span className="text-black">{oTag}</span>
                        <button
                          onClick={() => onRemoveOneTag(oTag)}
                          title={languages.remove}
                        >
                          <i className="fa fa-times text-black hover:text-blue-500"></i>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          
          <div className="flex flex-col mb-4">
            <h3 className="mb-3 text-black font-semibold">{languages.postContent}</h3>            
          </div>
          <RealTinyMceEditor 
            getContent={onPostContentchanged}
            hasToolbar={true} />

          <div className="w-full mt-4">
            <LinkAchorRound 
              text={languages.save} 
              type="button" 
              fullWidth={true}
              borderRadius={18}
              onClick={saveBlog} />
          </div>

          <div className="mb-8" />
        </div>
      </Card>
    </div>
  );
};

export default NewBlogPostPage;
