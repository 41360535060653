export const HTMLContentComponent = ({postContent, isBlog, cssStyle}: 
    {
      postContent: string, 
      isBlog: boolean,
      cssStyle?: string,
    }) => {
      const bBlog = isBlog;
      const finalClasses = bBlog ? 'blog-content px-4 md:px-0' : (!cssStyle ? '': cssStyle!);
  
  
      const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noopener,noreferrer");
      };
  
      const clickcontentHandler = (e: any) => {
        const targetLink = e.target.closest("a");
        if (!targetLink) return;
        e.preventDefault();
        openInNewTab(targetLink.href);
      };
      return (
        <div
          className={`${finalClasses} text-black`}
          onClick={clickcontentHandler}
          dangerouslySetInnerHTML={{ __html: postContent }}
        />
      );
  }