/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ImHistory } from 'react-icons/im';

import getLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import Card from 'src/components/Card';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';
import { IAlMessageInbox, IAlumniMessageAttach } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';
import appDeclarations from 'src/utils/declarations';



const languages = getLanguages();
const appFxs = generalFxs();

const MessagesPreviewPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [mainMessage, setMainMessage] = useState<IAlMessageInbox | null>(null);
  
  const {messageKey} = useParams();
  const navigate = useNavigate();
  const bLoaded = useRef<boolean>(false);


  document.title = `${languages.messages} | Alumni - ${languages.schoolNameOnly}`;


    

  const getMsgDataFromServer = async (bAjax: boolean) => {
    const operServer = await NetServices.requestGet(`alumni/getonerelatedmessage/${messageKey}`, bAjax);
    if(!operServer.bReturn){
      await appFxs.showAlert(operServer.msgTitle, operServer.msgBody);
      navigate('/app/main/messages');
      return;
    }

    setMainMessage(operServer.oneRelatedMessage!);
    setIsLoading(false);
  }
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const clickcontentHandler = (e: any) => {
    const targetLink = e.target.closest("a");
    if (!targetLink) return;
    e.preventDefault();
    openInNewTab(targetLink.href);
  };

  useEffect(()=> {
    if(bLoaded.current)return;
    getMsgDataFromServer(false);
    bLoaded.current = true;
  }, []);

  if(isLoading) return <LoadingWaitSpace />
  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex mb-3">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {mainMessage?.messageRecord.messageSubject}
              </h3>
              <div className="flex gap-1">                
                <button
                  type="button"
                  className="flex items-center p-3 rounded-full font-semibold bg-gray-200 hover:text-white hover:bg-gray-400"
                  onClick={() => getMsgDataFromServer(true)}
                >
                  <ImHistory />
                </button>
              </div>
            </div>
            <div className='flex flex-col'>
              <article onClick={clickcontentHandler} 
                className="prose prose-slate prose-a:text-blue-600 hover:prose-a:text-blue-500" dangerouslySetInnerHTML={{__html: mainMessage?.messageRecord.messageContent!}} />
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* Body */}
      {(mainMessage!.messageRecord.attachmentFiles!.length > 0) && 
        <MessageAttachmentsComp 
          messageKey={mainMessage!.messageRecord.messageKey} />}


      
    </div>
  )
}

export default MessagesPreviewPage;

const MessageAttachmentsComp = ({messageKey}:{messageKey: string}) => {
  const [isLoading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [listMsgAttachts, setlistMsgAttachts] = useState<IAlumniMessageAttach[]>([]);

  const [attachImgVids, setAttachImgVids] = useState<IAlumniMessageAttach[]>([]);
  const [attachOtherFiles, setAttachOtherFiles] = useState<IAlumniMessageAttach[]>([]);


  const bLoaded = useRef<boolean>(false);

  const loadListAttachements = async () => {
    const network = await NetServices.requestGet(`alumni/getlistofmessageattachment/${messageKey}`);
    if(!network.bReturn){
      setServerError(true);
      return;
    }

    setlistMsgAttachts(network.listMsgAttachts!);
    setServerError(false);
    setLoading(false);
  }

  const prepareAttachts = () => {
    const lstFiles = listMsgAttachts.filter(o => !appFxs.isFileOrImageExt(o.fileExtension));
    const lstVidImages = listMsgAttachts.filter(o => appFxs.isFileOrImageExt(o.fileExtension));

    setAttachImgVids(lstVidImages);
    setAttachOtherFiles(lstFiles);
  }

  useEffect(() => {
    if(bLoaded.current)return;
    loadListAttachements();
    bLoaded.current = true;
  }, []);

  useEffect(() => {
    prepareAttachts();
  }, [listMsgAttachts]);


  const onDownload = async (url: string, nameDown: string) => {
     appFxs.downloadFromFileUrl(url, nameDown);
  }
  const onPreview = (url: string) => {
    appFxs.openWindowUrl(url)
  }


  if(isLoading) return <LoadingWaitSpace />

  return (
    <Card>
      <div className="flex lfex-col">
        {serverError && 
          <div className=""></div>
        }
        <div className="flex flex-col gap-1 border border-slate-300 border-solid">
          <h3 className='ml-2 py-2 mb-3 text-black font-semibold'>{languages.attachments} ({listMsgAttachts.length})</h3>
          {attachImgVids.length > 0 && (
            <div className="flex flex-wrap py-2 px-2">
              {attachImgVids.map((oFile) => (
                <AttachDoctImageOrVideo
                key={oFile.attachtKey}
                  fileRec={oFile}
                  messageKey={messageKey} 
                  onDownload={onDownload}
                  onPreview={onPreview}
                />
              ))}
            </div>
          )}
          {attachOtherFiles.length > 0 && (
            <div className="flex flex-wrap py-2 px-2">
              {attachOtherFiles.map((oFile) => (
                <AttachDocuemntOther 
                key={oFile.attachtKey}
                  fileRec={oFile} 
                    messageKey={messageKey} 
                    onDownload={onDownload}
                    onPreview={onPreview} />
              ))}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}


const AttachDoctImageOrVideo = ({fileRec, messageKey, onDownload, onPreview}: IFileAttach) => {
  const ext = fileRec.fileExtension;

  const isImage = appFxs.isFileImageExt(ext);
  const isvideo = appFxs.isFileVideoExt(ext);

  return (
    <>
      {isImage && 
        <AttachDocumentImg 
        fileRec={fileRec} 
        messageKey={messageKey} 
        onDownload={onDownload}
        onPreview={onPreview}/>
      }
      {isvideo && 
        <AttachDocuemntVideo 
          fileRec={fileRec} 
          messageKey={messageKey} 
          onDownload={onDownload}
          onPreview={onPreview} />
      }
    </>
  );
}
const AttachDocumentImg = ({messageKey, fileRec, onDownload, onPreview}: IFileAttach) => {
  const urlFile = `${appDeclarations.apiUrlTest}/cloud_data/alumni_social_messages_attachts/${messageKey}/${fileRec.fileName}`;
  const savedName = fileRec.savedFileName;

  return (
    <div
      className="group w-[200px] aspect-video bg-slate-400 relative rounded-[4px] overflow-hidden mr-2 mb-2 border border-solid border-slate-400"
      style={{
        backgroundImage: `url(${urlFile})`,
        backgroundSize: "cover",
      }}
      onClick={() => {
        if(!onPreview)return;
        onPreview(urlFile);
      }}>
      <div className="hidden group-hover:flex absolute right-1 top-1 w-5 h-5 items-center justify-center cursor-pointer text-blue-500 hover:text-blue-700 rounded-full bg-gray-400 hover:bg-white"
      onClick={(e)=>{
        e.stopPropagation();
        if(!onDownload)return;
        onDownload(urlFile, savedName)
      }}>
        <i className="fa fa-download"></i>
      </div>
    </div>
  );
};
const AttachDocuemntVideo = ({messageKey, fileRec, onDownload, onPreview}: IFileAttach) => { 
  const urlFile = `${appDeclarations.apiUrlTest}/cloud_data/alumni_social_messages_attachts/${messageKey}/${fileRec.fileName}`;
  const savedName = fileRec.savedFileName;

  return (
    <div
      className="group w-[200px] aspect-video bg-slate-400 relative rounded-[4px] overflow-hidden mr-2 mb-2 border border-solid border-slate-400 before:block before:absolute before:bg-black before:bg-opacity-25 before:z-[1] before:w-full before:h-full before:top-0 before:left-0"
      style={{
        backgroundSize: "cover",
      }}
      onClick={() => {
        if(!onPreview)return;
        onPreview(urlFile);
      }}>
      {urlFile && <video src={urlFile} 
        autoPlay={true}
        muted={true}
        className={`absolute top-0 left-0 w-full h-full -z-[0]`}
         />}
      <div className="hidden group-hover:flex absolute right-1 top-1 w-5 h-5 items-center justify-center cursor-pointer text-blue-500 hover:text-blue-700 rounded-full bg-gray-400 hover:bg-white z-[3]"
      onClick={(e)=>{
        e.stopPropagation();
        if(!onDownload)return;
        onDownload(urlFile, savedName)
      }}>
        <i className="fa fa-download"></i>
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-[2]">
        <i className="fa fa-play-circle fa-2x text-black text-opacity-25"></i>
      </div>
    </div>
  );
};
const AttachDocuemntOther = ({messageKey, fileRec, onDownload, onPreview}: IFileAttach) => {
  const urlFile = `${appDeclarations.apiUrlTest}/cloud_data/alumni_social_messages_attachts/${messageKey}/${fileRec.fileName}`;
  const ext = '.' + fileRec.fileExtension;
  const savedName = fileRec.savedFileName;

  const name = fileRec.fileName;

  return (
    <div className="mr-2 mb-2 group text-gray-500 flex items-center text-sm gap-1 py-1 px-1 rounded-[16px] border border-solid border-slate-400"
    onClick={() => {
      if(!onPreview)return;
      onPreview(urlFile);
    }}>

      <span className="mr-1 w-5 h-5 rounded-full border border-solid border-slate-400 flex items-center justify-center">
        <i className={`${appFxs.filleExtCss(ext)}`}></i>
      </span>
      <span>{appFxs.shortenTextFileName(name)}</span>

      <span
        className="ml-1 text-gray-400 group-hover:text-gray-600 cursor-pointer w-5 h-5 rounded-full border border-solid border-slate-400 bg-slate-300 hover:bg-slate-400 flex items-center justify-center hover:text-white"
        onClick={(e)=>{
          e.stopPropagation();
          if(!onDownload)return;
          onDownload(urlFile, savedName)
        }}>
        <i className="fa fa-download"></i>
      </span>
    </div>
  );
};


interface IFileAttach {
  messageKey:string, 
  fileRec: IAlumniMessageAttach,
  onDownload?: (url: string, nameDown: string) => void,
  onPreview?: (url: string) => void,
}













